const fetch = async (url, options = {}) => {
    const headers = options.headers || {};
    const token = localStorage.authKey;
    headers['content-type'] = 'application/json';
    if (url !== `${process.env.API_ROOT}/guest/login`) {
        headers.Authorization = `Bearer ${token}`;
    }

    if (options.body) {
        options.body = JSON.stringify(options.body);
    }
    if (options.data) {
        options.data = JSON.stringify(options.data);
    }
    options.headers = headers;
    options.credentials = 'same-origin';
    // options.mode = 'no-cors';
    const makeRequest = async () => {
        const response = await window.fetch(url, options);
        return response;
    };
    const response = await makeRequest();
    if (response.status === 403) {
        window.location.replace('/login');
    } else if (response.status === 204) {
        return {};
    } else {
        let json;
        // if (headers.accept === 'text/csv') {
        json = await response.text();
        json = JSON.parse(json);
        // } else if (response.type === 'cors' || response.status === 204) {
        //     json = response;
        // } else {
        //     try {
        //         json = await response.json();
        //     } catch (err) {
        //         console.log('response error', err);
        //     }
        // }

        if ((url === `${process.env.API_ROOT}/guest/login` ||
            url === `${process.env.API_ROOT}/users/token?expand=authKey,accountId,email,username`) &&
            json.authKey) {
            localStorage.authKey = json.authKey;
        }
        return json;
    }
};

export default fetch;
