import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, Avatar, Button } from '@material-ui/core';
import { Person, BugReport } from '@material-ui/icons';
import logo from '../../styles/assets/img/logo.png';
const TopNav = React.memo(props => (
    <AppBar position='fixed'>
        <Toolbar className='toolbar'>
            <div className='flex'>
                <span className='display-1'>
                    <span className='font-light'>Report </span>Mule
                </span>
                <Avatar src={logo} />
            </div>
            <div className='flex baseline'>
                <Button
                    endIcon={<Person />}
                    color='inherit'
                    style={{marginRight: '10px'}}
                >
                    {props.currentUser.realName || ''}
                </Button>
                <BugReport />
            </div>
        </Toolbar>
    </AppBar>

));

TopNav.propTypes = {
    currentUser: PropTypes.object
};

export default TopNav;
