import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Grid,
    Card,
    IconButton,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    TextField,
    DialogActions
} from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';
import { updateCategory, archiveCategory } from '../../actions/categories';

const Category = React.memo(props => {
    const [ editOpen, setEditOpen ] = useState(false);
    const [ archiveOpen, setArchiveOpen ] = useState(false);
    const [ categoryName, setCategoryName ] = useState(props.category.name);

    useEffect(() => {
        setEditOpen(false);
    }, [props.category.name]);

    useEffect(() => {
        setArchiveOpen(false);
    }, [props.category]);

    const handleOpen = () => {
        setEditOpen(true);
    };

    const handleArchiveOpen = () => {
        setArchiveOpen(true);
    };

    const handleClose = () => {
        setEditOpen(false);
        setArchiveOpen(false);
    };

    const handleChange = (e) => {
        setCategoryName(e.target.value);
    };

    const handleSubmit = () => {
        props.updateCategory({id: props.category.id, name: categoryName, projectId: props.projectId});
    };

    const handleArchive = () => {
        props.archiveCategory({id: props.category.id, name: props.category.name, projectId: props.projectId});
    };
    return (
        <Card elevation={6} gutter={20} className={`list card ${props.isChild ? 'child-category' : ''}`}>
            <Grid container justify='space-between'>
                <Grid item xs={8}>
                    <p>{props.category.name}</p>
                </Grid>
                <Grid item xs={3}>
                    <IconButton onClick={handleOpen}>
                        <Edit />
                    </IconButton>
                    <IconButton onClick={handleArchiveOpen} >
                        <Delete />
                    </IconButton>
                </Grid>
            </Grid>
            <Dialog
                open={editOpen}
                onClose={handleClose}
                aria-labelledby='form-dialog-title'
                fullWidth
                maxWidth='md'
            >
                <DialogContent>
                    <TextField
                        autoFocus
                        margin='dense'
                        id='categoryName'
                        label='Category Name'
                        type='text'
                        fullWidth
                        value={categoryName}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit}>Submit</Button>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={archiveOpen}
                onClose={handleClose}
            >
                <DialogContent>
                    <DialogContentText>
                        {`This will archive "${props.category.name}"`}`
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleArchive}>Ok</Button>
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
});

Category.propTypes = {
    category: PropTypes.object,
    updateCategory: PropTypes.func,
    isChild: PropTypes.bool,
    projectId: PropTypes.string,
    archiveCategory: PropTypes.func
};
export default React.memo(connect(null, { updateCategory, archiveCategory })(Category));
