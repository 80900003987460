import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser } from '../../actions/login';
import {
    Card,
    CardContent,
    CardActions,
    Toolbar,
    // LinearProgress,
    Avatar,
    TextField,
    InputAdornment,
    Checkbox,
    FormControlLabel,
    Button,
    Grid,
    Paper
} from '@material-ui/core';
import { Person, Lock } from '@material-ui/icons';

import logo from '../../styles/assets/img/logo.png';

const LoginPage = props => {
    const { handleSubmit } = props;

    const [values, setValues] = React.useState({
        username: '',
        password: ''
    });
    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
    };

    const handleLogin = e => {
        props.loginUser(values);
    };

    return (
        <div className='login-page'>
            <Grid container direction='column' justify='center' alignItems='center' className='login-content'>
                <Grid container justify='center' alignItems='center'>
                    <Grid item xs={12} sm={8} md={4}>
                        <form onSubmit={handleSubmit}>
                            <Card className='login-card' elevation={12} raised square>
                                <Toolbar className='primary'>
                                    <span>Login</span>
                                    <Avatar src={logo} />
                                </Toolbar>
                                <CardContent>
                                    {/* <LinearProgress /> */}
                                    <TextField
                                        id='username'
                                        name='username'
                                        type='text'
                                        label='Login'
                                        value={values.username}
                                        onChange={handleChange('username')}
                                        fullWidth
                                        required
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    <Person />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    <TextField
                                        id='password'
                                        name='password'
                                        type='password'
                                        label='Password'
                                        value={values.password}
                                        fullWidth
                                        required
                                        onChange={handleChange('password')}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    <Lock />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </CardContent>
                                <CardActions>
                                    <Grid item xs={12} >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    value='remember'
                                                    onChange={handleChange('remember')}
                                                    checked={values.remember}
                                                    color='primary'
                                                />
                                            }
                                            label='Remember Me'
                                        />
                                        <Button className='lowercase'>Forgot Password?</Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button variant='contained' color='primary' className='primary' onClick={handleLogin}>LOG IN</Button>
                                    </Grid>
                                </CardActions>
                            </Card>
                        </form>
                    </Grid>
                </Grid>
                <Grid container justify='center'>
                    <Grid item xs={12} sm={8} md={4}>
                        <Paper elevation={12} square className='width-100' >
                            <Toolbar className='primary flex'>
                                <p>New User?</p>
                                <Button>Create Account</Button>
                            </Toolbar>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};
LoginPage.propTypes = {
    handleSubmit: PropTypes.func,
    loginUser: PropTypes.func
};

function mapStateToProps (state) {
    return { };
}

export default connect(mapStateToProps, {loginUser})(LoginPage);
