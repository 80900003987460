import Immutable from 'immutable';
import * as ActionType from '../actions/outputs';

const defaultState = Immutable.fromJS({
    outputs: [],
    output: {},
    outputKey: ''
});

function outputReducer (state = defaultState, action) {
    const { outputs, output, key } = action;

    switch (action.type) {
    case ActionType.FETCHED_OUTPUTS:
        return state.merge(Immutable.fromJS({ outputs }));
    case ActionType.FETCHED_OUTPUT:
        return state.merge(Immutable.fromJS({ output }));
    case ActionType.FETCHED_KEY:
        return state.merge(Immutable.fromJS({ outputKey: key }));
    default:
        return state;
    }
}
export default outputReducer;
