import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import { unarchiveCategory } from '../../actions/categories';

const ArchivedCategory = props => {
    const handleUnarchive = () => {
        const { name, id } = props.category;
        props.unarchiveCategory({name, id, projectId: props.projectId});
        props.handleClose();
    };

    return (
        <Grid item xs={6} className='archive-category-container'>
            <Paper elevation={6} className='archive-category'>
                <Grid container alignItems='center' justify='space-between'>
                    <Grid item xs={9}>
                        <p>{props.category.name}</p>
                    </Grid>
                    <Grid item xs={3}>
                        <Button className='success-text' onClick={handleUnarchive}>Restore</Button>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};
ArchivedCategory.propTypes = {
    category: PropTypes.object,
    projectId: PropTypes.string,
    unarchiveCategory: PropTypes.func,
    handleClose: PropTypes.func
};

export default connect(null, {unarchiveCategory})(ArchivedCategory);
