import fetch from '../util/fetch';
import _ from 'lodash';
import { successSnack, errorSnack } from './nav';
import { fetchProject } from './projects';

export const FETCHED_ARTICLES = Symbol('FETCHED_ARTICLES');
export const FETCHED_ARTICLE = Symbol('FETCHED_ARTICLE');
export const FETCHED_AUTHORS = Symbol('FETCHED_AUTHORS');
export const FETCHED_ISSUE_ARTICLES = Symbol('FETCHED_ISSUE_ARTICLES');
export const FETCHED_REPORT = Symbol('FETCHED_REPORT');
export const LOADING_SOCIAL = Symbol('LOADING_SOCIAL');
export const LOADING_RESULTS = Symbol('LOADING_RESULTS');
export const ARTICLES_UPDATED = Symbol('ARTICLES_UPDATED');

const fetchedArticles = ({articles}) => ({
    type: FETCHED_ARTICLES,
    articles
});

const fetchedArticle = ({article}) => ({
    type: FETCHED_ARTICLE,
    article
});

const fetchedAuthors = ({authors}) => ({
    type: FETCHED_AUTHORS,
    authors
});

const fetchedIssueArticles = ({issueArticles}) => ({
    type: FETCHED_ISSUE_ARTICLES,
    issueArticles
});

const fetchedReport = ({report}) => ({
    type: FETCHED_REPORT,
    report
});

const loadingSocial = (socialLoading) => ({
    type: LOADING_SOCIAL,
    socialLoading
});

const loadResults = ({loading}) => ({
    type: LOADING_RESULTS,
    loading
});

const articlesUpdated = (updatedArticles) => ({
    type: ARTICLES_UPDATED,
    updatedArticles
});

const updateArticles = () => dispatch => {
    dispatch(articlesUpdated(true));
    setTimeout(() => {
        dispatch(articlesUpdated(false));
    }, 5000);
};

export const fetchArticles = ({reportId}) => async (dispatch) => {
    try {
        dispatch(loadResults({loading: true}));
        const report = await fetch(`${process.env.API_ROOT}/reports/${reportId}`);
        dispatch(fetchedReport({report}));
        await dispatch(fetchProject({ projectId: report.projectId }));
        const response = await fetch(`${process.env.API_ROOT}/articles?reportId=${reportId}`);
        dispatch(fetchedArticles({articles: response}));
        dispatch(loadResults({loading: false}));
    } catch (err) {
        console.log('err', err);
        dispatch(loadResults({loading: false}));

        // dispatch(loginError({errorMessage}));
    }
};

export const fetchArticle = ({articleId}) => async (dispatch) => {
    try {
        const article = await fetch(`${process.env.API_ROOT}/articles/${articleId}`);
        dispatch(fetchedArticle({article}));
    } catch (err) {
        console.log('err', err);
    }
};

export const fetchIssueArticles = ({reportId}) => async (dispatch) => {
    try {
        dispatch(loadResults({loading: true}));
        const report = await fetch(`${process.env.API_ROOT}/reports/${reportId}`);
        dispatch(fetchedReport({report}));
        await dispatch(fetchProject({ projectId: report.projectId }));
        const response = await fetch(`${process.env.API_ROOT}/articles?reportId=${reportId}`);
        const issueArticles = response.filter(article => (!(article.issues.errors instanceof Array) && !_.isEmpty(article.issues.errors)));
        dispatch(fetchedIssueArticles({issueArticles}));
        dispatch(loadResults({loading: false}));
    } catch (err) {
        console.log('err', err);
        dispatch(loadResults({loading: false}));

        // dispatch(loginError({errorMessage}));
    }
};

export const updateSocialStats = ({ articleId, reportId }) => async dispatch => {
    try {
        dispatch(loadingSocial(true));
        await fetch(`${process.env.API_ROOT}/articles/${articleId}/refetch-social-media-stats`);
        dispatch(successSnack({text: 'Social stats have been updated.'}));
        dispatch(loadingSocial(false));
        dispatch(fetchArticles({reportId}));
        dispatch(fetchArticle({articleId}));
    } catch (err) {
        console.log('err', err);
        dispatch(errorSnack({text: 'Failed to update social stats'}));
        dispatch(loadingSocial(false));

        // dispatch(loginError({errorMessage}));
    }
};

export const saveUrl = ({ articleId, imageUrl, reportId }) => async dispatch => {
    try {
        await fetch(`${process.env.API_ROOT}/articles/${articleId}/upload-image`, {
            method: 'POST',
            body: {
                uri: imageUrl
            }
        });
        dispatch(successSnack({text: 'Image has been uploaded.'}));
        dispatch(fetchArticles({reportId}));
        dispatch(fetchArticle({articleId}));
    } catch (err) {
        console.log('upload error', err);
        dispatch(errorSnack({text: 'Failed to upload image'}));
    }
};

export const uploadImageFile = ({ file, articleId, reportId }) => async dispatch => {
    const reader = new FileReader();
    reader.onload = (event) => {
        dispatch(saveUrl({articleId, imageUrl: event.target.result, reportId}));
    };
    reader.readAsDataURL(file);
};

export const saveImage = ({ image, articleId, reportId }) => async dispatch => {
    await fetch(`${process.env.API_ROOT}/articles/${articleId}`, {
        method: 'PATCH',
        body: {
            image
        }
    });
    dispatch(successSnack({text: 'Image has been saved.'}));
    dispatch(fetchArticles({reportId}));
    dispatch(fetchArticle({articleId}));
};

export const moveArticle = ({ reportId, reportName, categoryId, article }) => async dispatch => {
    try {
        await fetch(`${process.env.API_ROOT}/reports/${reportId}/batch-move`, {
            method: 'POST',
            body: {
                articleIds: [article.id],
                attributes: {
                    category: categoryId
                }
            }
        });
        dispatch(successSnack({ text: `Selected article moved to ${reportName}`, link: `/reports/${reportId}/articles` }));
        dispatch(fetchArticles({reportId}));
    } catch (err) {
        console.log('err', err);
        dispatch(errorSnack({ text: 'Selected article failed to move.' }));
    }
};

export const copyArticle = ({ reportId, reportName, categoryId, article }) => async dispatch => {
    try {
        await fetch(`${process.env.API_ROOT}/reports/${reportId}/batch-copy`, {
            method: 'POST',
            body: {
                articleIds: [article.id],
                attributes: {
                    category: categoryId
                }
            }
        });
        dispatch(successSnack({ text: `Selected article copied to ${reportName}`, link: `/reports/${reportId}/articles` }));
        dispatch(fetchArticles({reportId}));
    } catch (err) {
        console.log('err', err);
        dispatch(errorSnack({ text: 'Selected article failed to copy.' }));
    }
};

export const deleteArticle = ({ article, reportId }) => async dispatch => {
    try {
        await fetch(`${process.env.API_ROOT}/articles/${article.id}`, {
            method: 'DELETE'
        });
        dispatch(successSnack({ text: `"${article.title}" deleted.` }));
        dispatch(fetchArticles({reportId}));
    } catch (err) {
        console.log('err', err);
        dispatch(errorSnack({ text: 'Selected article failed to delete.' }));
    }
};

export const refetchArticle = ({ articleId }) => async dispatch => {
    try {
        const article = await fetch(`${process.env.API_ROOT}/articles/${articleId}/refetch-data`);
        dispatch(fetchedArticle({article}));
    } catch (err) {
        console.log('err', err);
    }
};

export const fetchAuthors = () => async dispatch => {
    try {
        const authors = await fetch(`${process.env.API_ROOT}/articles/authors`);
        dispatch(fetchedAuthors({authors}));
    } catch (err) {
        console.log('err', err);
    }
};

export const updateArticle = ({
    articleId,
    category,
    pubName,
    title,
    author,
    date,
    summary,
    body,
    customData }) => async dispatch => {
    try {
        await fetch(`${process.env.API_ROOT}/articles/${articleId}`, {
            method: 'PATCH',
            body: {
                // categoryId: category,
                publicationName: pubName,
                title,
                author,
                date,
                summary,
                body,
                customData
            }
        });
    } catch (err) {
        console.log('err', err);
    }
};

export const addArticleUrls = ({ reportId, projectId, urls, customData, edit }) => async dispatch => {
    for (let i = 0; i < urls.length; i++) {
        const response = await fetch(`${process.env.API_ROOT}/articles`, {
            method: 'POST',
            body: {
                url: urls[i],
                projectId,
                reportId,
                customData
            }
        });
        if (urls.length === 1 && edit) {
            dispatch(fetchedArticle({ article: response }));
        } else {
            dispatch(updateArticles());
        }
    }
};
