import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dropzone from '../../components/modules/Dropzone';
import { saveUrl, saveImage, uploadImageFile } from '../../actions/articles';
import { Grid, Button, TextField, ButtonBase, Modal, Paper } from '@material-ui/core';

const ImageModal = React.memo(props => {
    const [ imageUrl, setImageUrl ] = useState('');
    const { item } = props;

    const handleImageUrl = (e) => {
        setImageUrl(e.target.value);
    };
    const handleSubmitUrl = () => {
        props.saveUrl({articleId: item.id, imageUrl, reportId: item.reportId});
        props.handleClose();
    };

    const uploadImageFile = ({file, itemId}) => {
        props.uploadImageFile({ file, articleId: itemId, reportId: item.reportId });
        props.handleClose();
    };

    const handleSelect = (e) => {
        const image = e.target.id;
        if (item.image !== image) {
            props.saveImage({articleId: item.id, image, reportId: item.reportId});
            props.handleClose();
        }
    };

    return (
        <Modal open={props.open} >
            <Grid container spacing={0} justify='center' >
                <Grid item xs={10} >
                    <Paper className='image-modal'>
                        <Grid container>
                            <h3 className='headline' >Upload Image</h3>
                            <Grid container className='url-container' alignItems='flex-end'>
                                <Grid item xs={11} style={{paddingRight: '5px'}} >
                                    <TextField
                                        fullWidth
                                        label='Image URL or Paste Image'
                                        id='imageUrl'
                                        value={imageUrl}
                                        onChange={handleImageUrl}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <Button variant='contained' color='primary' disabled={!imageUrl} onClick={handleSubmitUrl} >Add</Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Dropzone className='settings-dropzone' uploadFile={uploadImageFile} itemId={item.id} />
                            </Grid>
                            {item.images ? item.images.map(image => (
                                <Grid key={image} item xs={3}>
                                    <ButtonBase className={`image-button ${image === item.image ? 'selected' : ''}`} onClick={handleSelect} >
                                        <img src={image} id={image} className='settings-image' />
                                    </ButtonBase>
                                </Grid>
                            )) : ''}
                            {(!item.images || !item.images.length) && item.image ? (
                                <Grid key={item.image} item xs={3}>
                                    <ButtonBase className='image-button selected' onClick={handleSelect} >
                                        <img src={item.image} id={item.image} className='settings-image' />
                                    </ButtonBase>
                                </Grid>
                            ) : ''}
                            <Grid container justify='center' className='edit-submit-buttons' spacing={3}>
                                <Grid item>
                                    <Button id='' onClick={handleSelect} >No image </Button>
                                </Grid>
                                <Grid item>
                                    <Button id='' className='error-text' onClick={props.handleClose} >Close</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Modal>
    );
});

ImageModal.propTypes = {
    saveUrl: PropTypes.func,
    uploadImageFile: PropTypes.func,
    handleClose: PropTypes.func,
    saveImage: PropTypes.func,
    open: PropTypes.bool,
    item: PropTypes.object
};

export default React.memo(connect(null, {
    saveUrl,
    saveImage,
    uploadImageFile
})(ImageModal));
