import PublicationImage from '../../styles/assets/img/publications.svg';
import KeywordsImage from '../../styles/assets/img/keywords.svg';
import SearchImage from '../../styles/assets/img/search.svg';
import TwitterImage from '../../styles/assets/img/twitter.svg';
import ArticleImage from '../../styles/assets/img/importance.svg';

export default {
    userPrefs: [
        {
            title: 'Publications',
            value: 'publications',
            description: 'Enables you to view a list of all of the publications represented in each report along with reach data (based on an estimate of unique monthly visitors for each site).',
            image: PublicationImage,
            enabled: false
        },
        {
            title: 'Keywords',
            value: 'keywords',
            description: 'If you would like specific keywords shown in bold throughout your report you can enable the keyword menu. Information on creating your keywords can be found in our knowledge base.',
            image: KeywordsImage,
            enabled: false
        },
        {
            title: 'Search',
            value: 'search',
            description: 'Adds the ability to search across all of your reports.',
            image: SearchImage,
            enabled: false
        },
        {
            title: 'Twitter Stats',
            value: 'twitter',
            description: "Twitter removed sharing data from their platform several years ago. We're able to get a rough estimate of Twitter shares up to 99. Beyond that we simply indicate 99+. As this is imprecise and may cause more confusion than clarity, by default this feature is inactive.",
            image: TwitterImage,
            enabled: false
        },
        {
            title: 'Article Importance',
            value: 'importance',
            description: 'Adds the ability to adjust the level of importance each article has.',
            image: ArticleImage,
            enabled: false
        },
        {
            title: 'Hide Dashboard',
            value: 'dashboard',
            description: 'Hides Dashboard, allowing you to start right at the projects page.',
            enabled: false
        },
        {
            title: 'Article Author Issue',
            value: 'author',
            description: 'Show issue on an article if the author is blank.',
            enabled: false
        },
        {
            title: 'Clipboard Add',
            value: 'clipboard',
            description: 'Clipboard add stuff.',
            enabled: false
        }

    ],
    cardDisplay: [
        {
            title: 'General',
            value: 'cardStyle'
        },
        {
            title: 'Projects',
            value: 'cardStyle_projects'
        },
        {
            title: 'Reports',
            value: 'cardStyle_reports'
        },
        {
            title: 'Articles',
            value: 'cardStyle_articles'
        }
    ]

};
