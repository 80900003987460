import Immutable from 'immutable';
import * as ActionType from '../actions/nav';

const defaultState = Immutable.fromJS({
    loading: false,
    page: '',
    image: '',
    breadcrumbs: [],
    tabs: [],
    hideBreadcrumbs: false,
    snackText: '',
    snackVariant: 'success',
    snackLink: '',
    snackOpen: false
});

function navReducer (state = defaultState, action) {
    const { page, breadcrumbs, image, hideBreadcrumbs, snackText, snackVariant, snackLink, tabs } = action;
    switch (action.type) {
    case ActionType.LOADING_RESULTS:
        return state.merge(Immutable.fromJS({ loading: true }));
    case ActionType.STOP_LOADING_RESULTS:
        return state.merge(Immutable.fromJS({ loading: false }));
    case ActionType.SNACK_LOADED:
        return state.merge(Immutable.fromJS({ snackOpen: true, snackText, snackVariant, snackLink }));
    case ActionType.SNACK_UNLOADED:
        return state.merge(Immutable.fromJS({ snackOpen: false, snackText: '' }));
    case ActionType.PAGE_CHANGED:
        const data = {page, image, hideBreadcrumbs};
        if (breadcrumbs) {
            data.breadcrumbs = breadcrumbs;
        }
        data.tabs = tabs || [];
        return state.merge(Immutable.fromJS(data));
    default:
        return state;
    }
}
export default navReducer;
