import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { changePage } from '../../actions/nav';
import { fetchRecentReports } from '../../actions/reports';
import { fetchProjects } from '../../actions/projects';
// import SearchForm from './SearchForm';

const SearchPage = props => {
    useEffect(() => {
        props.changePage({
            page: 'Search',
            hideBreadcrumbs: true,
            breadcrumbs: []
        });
        props.fetchProjects();
        props.fetchUsers();
    }, []);

    return (
        <div>
            {/* <SearchForm projects={props.projects} /> */}
        </div>
    );
};

SearchPage.propTypes = {
    fetchProjects: PropTypes.func,
    changePage: PropTypes.func,
    fetchUsers: PropTypes.func
    // projects: PropTypes.array
};

function mapStateToProps (state) {
    const { projects } = state.projects.toJS();
    return { projects };
}

export default withRouter(connect(mapStateToProps, {changePage, fetchRecentReports, fetchProjects})(SearchPage));
