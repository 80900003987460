import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dropzone from '../../components/modules/Dropzone';
import { saveName, saveUrl, saveImage, uploadImageFile } from '../../actions/projects';
import { saveReportName, saveReportUrl, saveReportImage, uploadReportImageFile } from '../../actions/reports';
import { Grid, Button, TextField, ButtonBase } from '@material-ui/core';

const SettingsModal = props => {
    const [ name, setName ] = useState('');
    const [ imageUrl, setImageUrl ] = useState('');
    const { item, type } = props;

    useEffect(() => {
        setName(item.name);
    }, [item.name]);

    const handleName = (e) => {
        setName(e.target.value);
    };

    const handleSubmitName = () => {
        if (type === 'report') {
            props.saveReportName({reportId: item.id, name});
        } else {
            props.saveName({projectId: item.id, name});
        }
    };

    const handleImageUrl = (e) => {
        setImageUrl(e.target.value);
    };
    const handleSubmitUrl = () => {
        if (type === 'report') {
            props.saveReportUrl({reportId: item.id, imageUrl});
        } else {
            props.saveUrl({projectId: item.id, imageUrl});
        }
    };

    const uploadImageFile = ({file, itemId}) => {
        if (type === 'report') {
            props.uploadReportImageFile({ file, reportId: itemId });
        } else {
            props.uploadImageFile({ file, projectId: itemId });
        }
    };

    const handleSelect = (e) => {
        const image = e.target.id;
        if (item.image !== image) {
            if (type === 'report') {
                props.saveReportImage({reportId: item.id, image});
            } else {
                props.saveImage({projectId: item.id, image});
            }
        }
    };

    return (
        <Grid container spacing={0} justify='center' >
            <Grid item xs={12} lg={9} >
                <Grid container>
                    <Grid item xs={12} sm={9}>
                        <TextField
                            fullWidth
                            label={type === 'report' ? 'Report Name' : 'Project Name'}
                            id='name'
                            value={name}
                            onChange={handleName}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Button variant='contained' color='primary' onClick={handleSubmitName} >Submit</Button>
                    </Grid>
                    <h3>Upload Image</h3>
                    <Grid container>
                        <Grid item xs={11}>
                            <TextField
                                fullWidth
                                label='Image URL or Paste Image'
                                id='imageUrl'
                                value={imageUrl}
                                onChange={handleImageUrl}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant='contained' color='primary' disabled={!imageUrl} onClick={handleSubmitUrl} >Add</Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Dropzone className='settings-dropzone' uploadFile={uploadImageFile} itemId={item.id} />
                    </Grid>
                    {item.images ? item.images.map(image => (
                        <Grid key={image} item xs={3}>
                            <ButtonBase className={`image-button ${image === item.image ? 'selected' : ''}`} onClick={handleSelect} >
                                <img src={image} id={image} className='settings-image' />
                            </ButtonBase>
                        </Grid>
                    )) : ''}
                    {(!item.images || !item.images.length) && item.image ? (
                        <Grid key={item.image} item xs={3}>
                            <ButtonBase className='image-button selected' onClick={handleSelect} >
                                <img src={item.image} id={item.image} className='settings-image' />
                            </ButtonBase>
                        </Grid>
                    ) : ''}
                    <Grid container>
                        <Button id='' className='center-button' onClick={handleSelect} >No image </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

SettingsModal.propTypes = {
    saveName: PropTypes.func,
    saveUrl: PropTypes.func,
    uploadImageFile: PropTypes.func,
    saveImage: PropTypes.func,
    saveReportName: PropTypes.func,
    saveReportUrl: PropTypes.func,
    uploadReportImageFile: PropTypes.func,
    saveReportImage: PropTypes.func,
    type: PropTypes.string,
    item: PropTypes.object
};

export default connect(null, {
    saveName,
    saveUrl,
    saveImage,
    uploadImageFile,
    saveReportName,
    saveReportUrl,
    saveReportImage,
    uploadReportImageFile
})(SettingsModal);
