import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isoCountries from 'i18n-iso-countries';
import { Modal, Grid, Paper, TextField, Button, Select, MenuItem } from '@material-ui/core';
isoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
const countries = isoCountries.getNames('en');
const EditPublication = props => {
    const [ name, setName ] = useState('');
    const [ urlId, setUrl ] = useState('');
    const [ popularity, setPopularity ] = useState('');
    const [ selectedCountry, setSelectedCountry ] = useState('');

    useEffect(() => {
        setName(props.pub.name || '');
        setUrl(props.pub.urlId || '');
        setPopularity(props.pub.popularityManual || '');
        setSelectedCountry(props.pub.country);
    }, [props.open]);

    const handleName = (e) => {
        setName(e.target.value);
    };
    const handleUrl = (e) => {
        setUrl(e.target.value);
    };
    const handlePopularity = (e) => {
        setPopularity(e.target.value);
    };

    const handleCountry = (e) => {
        setSelectedCountry(e.target.value);
    };

    const handleSubmit = () => {
        props.updatePub({id: props.pub.id, name, urlId, popularity, country: selectedCountry});
    };

    return (
        <Modal open={props.open} onClick={props.closeModal} >
            <Grid container>
                <Paper className='center edit-pub-modal' onClick={(e) => { e.stopPropagation(); }} >
                    <TextField
                        label='Name'
                        id='name'
                        value={name}
                        onChange={handleName}
                    />
                    <TextField
                        label='URL ID'
                        id='urlId'
                        value={urlId}
                        onChange={handleUrl}
                    />
                    <Select
                        id='countries'
                        value={selectedCountry}
                        onChange={handleCountry}
                        fullWidth
                    >
                        {Object.keys(countries).map(code => (
                            <MenuItem value={code} key={code} >{`${code} - ${countries[code]}`}</MenuItem>

                        ))}
                    </Select>
                    <TextField
                        label='Manual Popularity'
                        id='popularity'
                        value={popularity}
                        onChange={handlePopularity}
                    />
                    <Button variant='contained' onClick={handleSubmit} >Submit</Button>
                    <Button onClick={props.closeModal} >Close</Button>
                </Paper>
            </Grid>
        </Modal>
    );
};

EditPublication.propTypes = {
    pub: PropTypes.object,
    open: PropTypes.bool,
    updatePub: PropTypes.func,
    closeModal: PropTypes.func
};

export default EditPublication;
