import Immutable from 'immutable';
import * as ActionType from '../actions/articles';

const defaultState = Immutable.fromJS({
    articles: [],
    authors: [],
    article: {},
    issueArticles: [],
    report: {},
    socialLoading: false,
    loading: false,
    refetchCount: 0,
    updatedArticles: false
});

function articleReducer (state = defaultState, action) {
    const { articles, article, authors, issueArticles, report, socialLoading, loading, updatedArticles } = action;

    switch (action.type) {
    case ActionType.FETCHED_ARTICLES:
        return state.merge(Immutable.fromJS({ articles }));
    case ActionType.FETCHED_AUTHORS:
        return state.merge(Immutable.fromJS({ authors }));
    case ActionType.FETCHED_ARTICLE:
        return state.merge(Immutable.fromJS({ article, refetchCount: state.refetchCount + 1 }));
    case ActionType.FETCHED_ISSUE_ARTICLES:
        return state.merge(Immutable.fromJS({ issueArticles }));
    case ActionType.FETCHED_REPORT:
        return state.merge(Immutable.fromJS({ report }));
    case ActionType.LOADING_SOCIAL:
        return state.merge(Immutable.fromJS({ socialLoading }));
    case ActionType.LOADING_RESULTS:
        return state.merge(Immutable.fromJS({ loading }));
    case ActionType.ARTICLES_UPDATED:
        return state.merge(Immutable.fromJS({ updatedArticles }));
    default:
        return state;
    }
}
export default articleReducer;
