import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ColorHash from 'color-hash';
import { Link } from 'react-router-dom';
import {
    Card,
    CardContent,
    CardActions,
    Button,
    IconButton,
    Typography,
    Menu,
    MenuItem,
    Grid, Chip
} from '@material-ui/core';
import { MoreVert, Facebook, Twitter } from '@material-ui/icons';

const RMListCard = props => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [ anchorEl, setAnchorEl ] = useState(null);

    const colorHash = new ColorHash();
    const { item, options } = props;
    const headerStyle = item.image
        ? {
            backgroundImage: `url(${item.image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center'
        }
        : {
            backgroundColor: colorHash.hex(item.title)
        };

    const handleMenu = (e) => {
        setAnchorEl(e.currentTarget);
        setMenuOpen(true);
    };

    const closeMenu = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    };

    const handleEdit = () => {
        props.handleEdit({articleId: item.id});
    };

    const selectOption = (idx) => {
        props.handleOption({articleId: item.id, option: idx});
        closeMenu();
    };

    return (
        <Grid item xs={12}>
            <Card elevation={6} gutter={20} className='list card'>
                <Grid container >
                    <Grid item xs={10}>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={3}>
                                    <Link to={props.link}>
                                        <div
                                            className='article list-card-image'
                                            style={headerStyle}
                                        >
                                            {!item.image
                                                ? <Typography className='list-card-header-text display-1'>
                                                    {item.title ? item.title.split(' ')
                                                        .reduce((prev, curr) => (prev + curr[0]), '')
                                                        .slice(0, 3)
                                                        .toUpperCase() : ''}
                                                </Typography> : ''}
                                        </div>
                                    </Link>
                                </Grid>
                                <Grid className='flex column' item xs={7}>
                                    <p className=' body-2 blue-text' >{item.user ? item.user.realName : ''}
                                        <span className='black-text'> added </span>
                                        {moment(item.added).fromNow()}
                                    </p>
                                    <Typography className='card-details sub-title'>{`${item.author || ''} / ${item.date || ''} / ${item.publicationName || ''}`}</Typography>
                                    <Typography className='list-card-title body-2'>{item.title}</Typography>
                                    <Link to={item.url} target='_blank' className='list-card-link sub-title'>{item.url}</Link>

                                </Grid>
                                <Grid item xs={2}>
                                    <Chip
                                        icon={<Facebook className='white-text' />}
                                        label={item.socialStats ? item.socialStats.facebook_total : 0}
                                        className='facebook-chip white-text'
                                    />
                                    <Chip
                                        icon={<Twitter className='white-text' />}
                                        label={item.socialStats ? item.socialStats.twitter : 0}
                                        className='twitter-chip white-text'
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Grid>
                    <Grid item xs={2} >
                        <CardActions >
                            <Grid container>
                                <Grid className='flex column' item xs={6}>
                                    <Button onClick={handleEdit} >Edit</Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <IconButton
                                        aria-label='more'
                                        aria-controls='card-menu'
                                        aria-haspopup='true' onClick={handleMenu} >
                                        <MoreVert />
                                    </IconButton>
                                    <Menu
                                        PaperProps={{
                                            style: {
                                                width: 200
                                            }
                                        }}
                                        open={menuOpen}
                                        onClose={closeMenu}
                                        id='card-menu'
                                        anchorEl={anchorEl}
                                        transformOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right'
                                        }}
                                    >
                                        {options.map((option, idx) => (
                                            <MenuItem key={option} selected={false}
                                                onClick={(e) => selectOption(idx)}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Grid>
                            </Grid>
                        </CardActions>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    );
};

RMListCard.propTypes = {
    item: PropTypes.object,
    handleEdit: PropTypes.func,
    handleOption: PropTypes.func,
    link: PropTypes.string,
    options: PropTypes.array
};
export default RMListCard;
