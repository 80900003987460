import React from 'react';
import PropTypes from 'prop-types';
import {
    Snackbar,
    SnackbarContent,
    Button
} from '@material-ui/core';

const MySnackBar = React.memo(props => {
    return (
        <Snackbar
            anchorOrigin={{ vertical: props.vertical || 'top', horizontal: props.horizontal || 'right' }}
            open={props.open}
            onClose={props.handleClose}
        >
            <SnackbarContent
                message={props.text}
                className={`snackbar-content ${props.variant || 'success'}`}
                action={
                    <div>
                        {props.link ? <Button href={props.link}>Go</Button> : ''}
                        <Button key='close' aria-label='close' color='inherit' onClick={props.handleClose}>
          Close
                        </Button>
                    </div>
                }

            />
        </Snackbar>
    );
});

MySnackBar.propTypes = {
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
    link: PropTypes.string,
    open: PropTypes.bool,
    variant: PropTypes.string,
    text: PropTypes.string,
    handleClose: PropTypes.func
};

export default MySnackBar;
