import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField
} from '@material-ui/core';
import 'react-sortable-tree/style.css';
import SortableTree from 'react-sortable-tree';
import Theme from 'react-sortable-tree-theme-minimal';
import { fetchProjectAndCategories, reorderCategories, createCategory } from '../../actions/categories';
import { changePage } from '../../actions/nav';
import Category from '../../components/categories/Category';
import ArchivedCategories from '../../components/categories/ArchivedCategories';

const CategoriesPage = props => {
    const [ projectId, setProjectId ] = useState(null);
    const [ archiveOpen, setArchiveOpen ] = useState(false);
    const [ newOpen, setNewOpen ] = useState(true);
    const [ data, setData ] = useState([]);
    const [ newCategory, setNewCategory ] = useState('');
    const [ archivedCategories, setArchivedCategories ] = useState([]);

    useEffect(() => {
        const { id } = props.match.params;
        setProjectId(id);
        props.fetchProjectAndCategories({projectId: id});
    }, [props.match.params.id]);

    useEffect(() => {
        props.changePage({
            page: props.project.name,
            image: props.project.image,
            tabs: ['reports', 'categories', 'outputs', 'settings'],
            breadcrumbs: [
                {
                    name: 'Dashboard',
                    path: '/'
                },
                {
                    name: 'Projects',
                    path: '/projects'
                }
            ]
        });
    }, [props.project.id]);

    useEffect(() => {
        console.log('reducing', props.categories.length);
        const data = props.categories.filter(category => (!category.isArchived)).reduce((prev, category) => {
            const node = {
                title: <Category category={category} projectId={projectId} isChild={category.level !== 1} />,
                name: category.name,
                id: category.id,
                level: category.level,
                isArchived: category.isArchived,
                expanded: true
            };
            if (node.level === 1) {
                prev.push(node);
            } else {
                const parent = prev[prev.length - 1];
                parent.children = parent.children || [];
                parent.children.push(node);
            }
            return prev;
        }, []);
        const archivedCategories = props.categories.filter(category => (category.isArchived));
        if (data.length) {
            setData(data);
        }
        if (archivedCategories.length) {
            setArchivedCategories(archivedCategories);
        }
        if (newOpen) {
            handleNew();
        }
    }, [props.categories]);

    const removeTitle = (cat) => {
        const catObj = Object.assign({}, cat);
        delete catObj.title;
        if (catObj.children) {
            catObj.children = catObj.children.map(child => (removeTitle(child)));
        }
        return catObj;
    };

    const handleChange = (data) => {
        setData(data);
        const order = data
            .map(cat => (removeTitle(cat)))
            .reduce((prev, curr) => {
                const parent = Object.assign({}, curr);
                parent.level = 1;
                prev.push(parent);
                if (curr.children) {
                    curr.children.forEach(child => {
                        child.level = 2;
                        prev.push(child);
                    });
                }
                return prev;
            }, []);

        props.reorderCategories({order, projectId});
    };

    const handleArchive = () => {
        setArchiveOpen(!archiveOpen);
    };

    const handleNew = () => {
        setNewOpen(!newOpen);
    };

    const handleChangeNew = (e) => {
        const { value } = e.target;
        setNewCategory(value);
    };

    const handleSubmit = () => {
        props.createCategory({name: newCategory, projectId});
    };

    return (
        <Grid container direction='column' alignItems='center'>
            <ArchivedCategories
                open={archiveOpen}
                categories={archivedCategories}
                handleClose={handleArchive}
                projectId={projectId}
            />
            <Dialog
                open={newOpen}
                onClose={handleNew}
                aria-labelledby='form-dialog-title'
                fullWidth
                maxWidth='md'
            >
                <DialogContent>
                    <TextField
                        autoFocus
                        margin='dense'
                        id='categoryName'
                        label='Category Name'
                        type='text'
                        fullWidth
                        error={!newCategory}
                        helperText='Field is required'
                        value={newCategory}
                        onChange={handleChangeNew}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} disabled={!newCategory}>Submit</Button>
                    <Button onClick={handleNew}>Close</Button>
                </DialogActions>
            </Dialog>
            <Grid item xs={2}>
                <Button onClick={handleArchive} variant='contained' className='center-button green-button'>View Archived</Button>
            </Grid>
            <Grid item xs={2}>
                <Button onClick={handleNew} variant='contained' color='primary' className='center-button' >New Category</Button>
            </Grid>
            <SortableTree
                treeData={data}
                onChange={handleChange}
                isVirtualized={false}
                theme={Theme}
            />
        </Grid>
    );
};

function mapStateToProps (state) {
    const { categories } = state.categories.toJS();
    const { project } = state.projects.toJS();
    const { loading } = state.nav.toJS();
    return { categories, loading, project };
}

CategoriesPage.propTypes = {
    match: PropTypes.object,
    fetchProjectAndCategories: PropTypes.func,
    changePage: PropTypes.func,
    createCategory: PropTypes.func,
    project: PropTypes.object,
    reorderCategories: PropTypes.func,
    categories: PropTypes.array
};

export default connect(mapStateToProps, { fetchProjectAndCategories, reorderCategories, changePage, createCategory })(CategoriesPage);
