import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

const MyDropzone = props => {
    const handleDrop = acceptedFiles => {
    // Do something with the files
        props.uploadFile({file: acceptedFiles[0], itemId: props.itemId});
    };

    return (
        <Dropzone onDrop={handleDrop} >
            {({getRootProps, getInputProps}) => {
                return (
                    <div {...getRootProps()} className={props.className || ''} >
                        <input {...getInputProps()} />
                        <p className='center-text headline'>Drop Image Here</p>
                        <p className='center-text'>click to open dialog</p>
                    </div>
                );
            }}
        </Dropzone>);
};

MyDropzone.propTypes = {
    uploadFile: PropTypes.func,
    itemId: PropTypes.string,
    className: PropTypes.string
};

export default MyDropzone;
