import fetch from '../util/fetch';

// export const FETCHED_ARTICLES = Symbol('FETCHED_ARTICLES');

// const fetchedArticles = ({articles}) => ({
//     type: FETCHED_ARTICLES,
//     articles
// });
export const saveCard = ({ accountId, token }) => async dispatch => {
    await fetch(`/accounts/${accountId}/card`, {
        method: 'POST',
        body: {
            token
        }
    });
};
