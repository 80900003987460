import React from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    FormControlLabel,
    Checkbox,
    FormControl,
    FormLabel,
    Select,
    InputLabel,
    MenuItem
} from '@material-ui/core';

const CustomInputs = React.memo(props => {
    const { project } = props;
    const fields = project.customFields.filter(field => (field.name !== 'category'));
    const values = props.customData;

    const handleSelect = (e, name) => {
        props.handleCustom({name, value: e.target.value});
    };
    const handleCheck = (e, name) => {
        props.handleCustom({name, value: e.target.checked ? '1' : '0'});
    };

    return (
        <Grid container spacing={1} className='custom-inputs'>
            {fields.map((field, idx) => {
                const { items, name, label } = field;
                if (!items) {
                    return '';
                }
                const value = values[name];
                if (items.length === 1) {
                    return (
                        <Grid item md={6} key={`${field.name}-${idx}`} >
                            <FormControl>
                                <FormLabel>{label}</FormLabel>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={!!+value}
                                            onChange={(e) => handleCheck(e, name)}
                                            value={value}
                                            color='primary'
                                        />
                                    }
                                    label={items[0].label}
                                />
                            </FormControl>
                        </Grid>
                    );
                } else {
                    return (
                        <Grid item md={6} key={`${field.name}-${idx}`}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor={name}>{label}</InputLabel>
                                <Select
                                    id={name}
                                    value={value}
                                    onChange={(e) => handleSelect(e, name)}
                                    fullWidth
                                >
                                    {items.map(item => (
                                        <MenuItem value={item.value} key={item.value} >{item.label}</MenuItem>

                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    );
                }
            })}
        </Grid>
    );
});

CustomInputs.propTypes = {
    customData: PropTypes.object,
    handleCustom: PropTypes.func,
    project: PropTypes.object
};

export default CustomInputs;
