import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UserPreferenceCard from '../../components/settings/UserPreferenceCard';
import SnackBar from '../../components/modules/SnackBar';
import { fetchProjects } from '../../actions/projects';
import { changePage } from '../../actions/nav';
import { storeUserPrefs, storeGridPrefs } from '../../actions/settings';
import { Grid } from '@material-ui/core';
import settingsConfig from './settingsConfig';
import GridListButton from './GridListButton';
import CreditCardForm from './CreditCardForm';

const SettingsContainer = props => {
    const [ userPrefs, setUserPrefs ] = useState(settingsConfig.userPrefs);
    const [ gridPrefs, setGridPrefs ] = useState(settingsConfig.cardDisplay);
    const [ snackText, setSnackText ] = useState('');
    const [ snackOpen, setSnackOpen ] = useState(false);

    useEffect(() => {
        const prefs = userPrefs.slice();
        const localPrefs = JSON.parse(localStorage.getItem('prefs'));
        if (localPrefs && localPrefs.uid === props.currentUser.id) {
            localPrefs.items.forEach((localPref) => {
                const exists = prefs.find(pref => pref.value === localPref);
                if (exists) {
                    exists.enabled = true;
                }
            });
            setUserPrefs(prefs);
        }
        const grids = gridPrefs.slice();
        grids.forEach((gridPref) => {
            const localPref = JSON.parse(localStorage.getItem(gridPref.value));
            gridPref.index = localPref || 0;
        });
        setGridPrefs(grids);
    }, [props.currentUser.id]);

    const requestClipboard = () => {
        navigator.clipboard.readText().then()
            .catch(() => {
                // Catch error
            });
    };

    const handleToggle = (value, checked, name) => {
        // Clipboard Add is special
        if (value === 'clipboard' && checked === true) {
            requestClipboard();
        }
        if (checked) {
            setSnackText(`Enabled ${name} for your account.`);
            setSnackOpen(true);
        } else {
            setSnackText(`Disabled ${name} for your account.`);
            setSnackOpen(true);
        }
        const pref = userPrefs.find(userPref => userPref.value === value);
        pref.enabled = checked;
        setUserPrefs(userPrefs);
        const prefs = userPrefs
            .filter(pref => pref.enabled)
            .map(pref => pref.value);
        const prefsObj = { uid: props.currentUser.id, items: prefs };
        localStorage.setItem('prefs', JSON.stringify(prefsObj));
        props.storeUserPrefs(prefsObj);
    };

    const closeSnack = () => {
        setSnackOpen(false);
    };

    return (
        <Grid container justify='center' spacing={0}>
            <SnackBar
                text={snackText}
                open={snackOpen}
                variant='success'
                handleClose={closeSnack}
            />
            <Grid item xs={12}>
                <h2>User Preferences</h2>
            </Grid>
            <Grid item xs={12}>
                <p>Add functionality to your account with these advanced features.</p>
            </Grid>
            <Grid container spacing={4}>
                {userPrefs.map(setting => {
                    return (
                        <UserPreferenceCard
                            key={setting.title}
                            title={setting.title}
                            description={setting.description}
                            image={setting.image}
                            value={setting.value}
                            enabled={setting.enabled}
                            handleToggle={handleToggle}
                        />
                    );
                })}
            </Grid>
            <Grid item xs={12}>
                <h2>Card Display Style</h2>
            </Grid>
            {gridPrefs.map(card => {
                const handleChange = (e, newValue) => {
                    localStorage.setItem(card.value, JSON.stringify(newValue));
                    const prefs = gridPrefs.slice();
                    const pref = prefs.find(p => p.value === card.value);
                    pref.index = newValue;
                    setGridPrefs(prefs);
                    props.storeGridPrefs(prefs);
                };
                return (
                    <Grid key={card.title} item xs={12} lg={3}>
                        <GridListButton
                            title={card.title}
                            value={card.index || 0}
                            handleChange={handleChange}
                        />
                    </Grid>
                );
            })}
            <Grid item xs={6}>
                <CreditCardForm />

            </Grid>
        </Grid>
    );
};

SettingsContainer.propTypes = {
    currentUser: PropTypes.object,
    storeUserPrefs: PropTypes.func,
    storeGridPrefs: PropTypes.func
};

function mapStateToProps (state) {
    const { projects, ascending } = state.projects.toJS();
    const { loading } = state.nav.toJS();
    const { currentUser } = state.login.toJS();
    return { projects, ascending, loading, currentUser };
}
export default connect(mapStateToProps, { fetchProjects, changePage, storeUserPrefs, storeGridPrefs })(SettingsContainer);
