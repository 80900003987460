import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePowerpoint, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import {
    Toolbar,
    IconButton,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    Radio,
    Grid
} from '@material-ui/core';
import { Close, BugReport, Add, Print, Public } from '@material-ui/icons';
import { fetchOutput, fetchOutputKey } from '../../actions/outputs';

const Output = props => {
    const [reportId, setReportId] = useState(0);
    const [projectId, setProjectId] = useState(0);
    const [outputId, setOutputId] = useState(props.match.params.id);
    const [preview, setPreview] = useState('web');

    useEffect(() => {
        const { id } = props.match.params;
        props.fetchOutput({ outputId: id });
    }, [props.match.params.id]);

    useEffect(() => {
        setProjectId(props.output.projectId);
    }, [props.output]);

    const handleOutput = (e) => {
        setOutputId(e.target.value);
        if (reportId) {
            props.fetchOutputKey({outputId: e.target.value, reportId});
        }
    };

    const handleReport = (e) => {
        setReportId(e.target.value);
        console.log(e.target.value);
        props.fetchOutputKey({reportId: e.target.value, outputId});
    };

    const handlePreview = (e) => {
        setPreview(e.target.value);
    };

    const goBack = () => {
        props.history.replace(`/projects/${projectId}/outputs`);
    };

    return (
        <div>
            <Toolbar>
                <IconButton onClick={goBack} >
                    <Close />
                </IconButton>
                <span>Output Report</span>
                <IconButton>
                    <BugReport />
                </IconButton>
            </Toolbar>
            <Grid container>
                <Grid item sm={3} lg={2} >
                    <div>
                        <FormControl margin='normal' fullWidth>
                            <InputLabel>Output*</InputLabel>
                            <IconButton>
                                <Add />
                            </IconButton>
                            <Select
                                id='output'
                                value={outputId}
                                onChange={handleOutput}
                            >
                                {props.outputs.map(output => (
                                    <MenuItem value={output.id}>{output.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl margin='normal' fullWidth>
                            <InputLabel>Report*</InputLabel>
                            <Select
                                id='report'
                                value={reportId}
                                onChange={handleReport}
                            >
                                {props.reports.map(report => (
                                    <MenuItem value={report.id}>{report.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl margin='normal' component='fieldset' fullWidth >
                            <FormLabel component='legend'>Preview:</FormLabel>
                            <RadioGroup aria-label='header' name='header' value={preview} onChange={handlePreview}>
                                <FormControlLabel value='print' control={<Radio />} label='Print' />
                                <FormControlLabel value='web' control={<Radio />} label='Web' />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </Grid>
                <Grid item sm={9} lg={10}>
                    <h3>Share</h3>
                    <div>
                        <IconButton href={`http://localhost:8008/print/${props.outputKey}`} target='_blank'>
                            <Print />
                        </IconButton>
                        <IconButton href={`http://localhost:8008/web/${props.outputKey}`} target='_blank'>
                            <Public />
                        </IconButton>
                        <IconButton href={`http://localhost:8008/excel/${props.outputKey}`} target='_blank'>
                            <FontAwesomeIcon icon={faFileExcel} />
                        </IconButton>
                        <IconButton href={`http://localhost:8008/powerpoint/${props.outputKey}`} target='_blank'>
                            <FontAwesomeIcon icon={faFilePowerpoint} />
                        </IconButton>
                    </div>
                    {props.outputKey
                        ? <iframe src={`http://localhost:8008/${preview}/${props.outputKey}`} className='output-iframe' />
                        : ''}
                </Grid>
            </Grid>
        </div>
    );
};

Output.propTypes = {
    match: PropTypes.object,
    fetchOutputKey: PropTypes.func,
    fetchOutput: PropTypes.func,
    outputs: PropTypes.array,
    history: PropTypes.object,
    output: PropTypes.object,
    outputKey: PropTypes.string,
    reports: PropTypes.array
};

function mapStateToProps (state) {
    const { outputs, output, outputKey } = state.outputs.toJS();
    const { reports } = state.reports.toJS();
    return { outputs, reports, output, outputKey };
}

export default connect(mapStateToProps, { fetchOutput, fetchOutputKey })(Output);
