import Immutable from 'immutable';
import * as ActionType from '../actions/keywords';

const defaultState = Immutable.fromJS({
    keywordLists: [],
    keywords: []
});

function articleReducer (state = defaultState, action) {
    const { keywordLists, keywords } = action;

    switch (action.type) {
    case ActionType.FETCHED_KEYWORD_LISTS:
        return state.merge(Immutable.fromJS({ keywordLists }));
    case ActionType.FETCHED_KEYWORDS:
        return state.merge(Immutable.fromJS({ keywords }));
    default:
        return state;
    }
}
export default articleReducer;
