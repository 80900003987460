import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#363434'
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            light: '#1d97c1',
            main: '#1d97c1'
            // dark: will be calculated from palette.secondary.main,
            // contrastText: '#ffcc00',
        },
        error: {
            main: '#d7263d'
        },
        info: {
            main: '#2196f3'
        },
        success: {
            main: '#27ae60'
        },
        accent: {
            main: '#20cae7'
        }
    }
});

export default theme;
