import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import LoginPage from '../../components/login/LoginPage';
import { checkUser } from '../../actions/login';
// import ForgotPassword from '../../components/login/ForgotPassword';
// import PasswordSent from '../../components/login/PasswordSent';
// import Footer from '../../components/nav/Footer';
// import { login, sendPassword, resetPasswordError } from '../../actions/login';

class LoginPageContainer extends Component {
    constructor (props) {
        super(props);
        // this.handleSubmit = this.handleSubmit.bind(this);
        // this.handlePassword = this.handlePassword.bind(this);
        // this.handleFocus = this.handleFocus.bind(this);
        // this.props.checkUser();
        if (localStorage.authKey) {
            this.props.history.push('/projects');
        }
    }

    componentDidUpdate () {
        if (this.props.currentUser && this.props.currentUser.id) {
            this.props.history.push('/projects');
        }
        // if (this.props.location.pathname === '/forgotPassword' && nextProps.location.pathname === '/login') {
        //     this.props.sendPassword({email: ''});
        // }
    }

    // handleSubmit (values) {
    //     const { email, password } = values;
    //     this.props.login({email, password});
    // }

    // handleFocus () {
    //     this.props.resetPasswordError();
    // }

    // handlePassword (values) {
    //     const { email } = values;
    //     this.props.sendPassword({email});
    // }

    render () {
        // const { pathname } = this.props.location;
        // const { passwordSent, email, passwordError } = this.props;
        let component = <LoginPage onSubmit={this.handleSubmit} />;
        // if (pathname === '/forgotPassword') {
        //     if (!passwordSent || passwordError) {
        //         component = <ForgotPassword
        //             onSubmit={this.handlePassword}
        //             passwordError={passwordError}
        //             handleFocus={this.handleFocus}
        //         />;
        //     } else {
        //         component = <PasswordSent email={email} />;
        //     }
        // }

        return (
            <div className='login-page-container'>
                {component}
            </div>
        );
    }
}

LoginPageContainer.propTypes = {
    history: PropTypes.object,
    currentUser: PropTypes.object
    // checkUser: PropTypes.func
};

const mapStateToProps = (state) => {
    const { currentUser } = state.login.toJS();
    return {
        currentUser
    };
};

export default withRouter(connect(mapStateToProps, {checkUser})(LoginPageContainer));
