import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Card, Typography, CardActions, Button, Grid } from '@material-ui/core';

const ConfirmDialog = React.memo(props => {
    return (
        <Dialog open={props.open} >
            <Card className='confirm-dialog'>
                <div className='confirm-title'>
                    <Typography className='white-text' >{props.text}</Typography>
                </div>
                <CardActions className='confirm-actions'>
                    <Grid container justify='flex-end'>
                        <Button color='error' className='error-text' onClick={props.handleConfirm} >Ok</Button>
                        <Button onClick={props.handleClose} >Cancel</Button>
                    </Grid>
                </CardActions>
            </Card>
        </Dialog>
    );
});

ConfirmDialog.propTypes = {
    open: PropTypes.bool,
    text: PropTypes.string,
    handleClose: PropTypes.func,
    handleConfirm: PropTypes.func
};

export default ConfirmDialog;
