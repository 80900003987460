import Immutable from 'immutable';
import * as ActionType from '../actions/superUser';

const defaultState = Immutable.fromJS({
    publications: [],
    accounts: [],
    users: [],
    projects: [],
    fetching: false
});

function superUserReducer (state = defaultState, action) {
    const { publications, users, accounts, projects } = action;

    switch (action.type) {
    case ActionType.FETCHING_PUBLICATIONS:
        return state.merge(Immutable.fromJS({ fetching: true }));
    case ActionType.FETCHED_PUBLICATIONS:
        return state.merge(Immutable.fromJS({ fetching: false, publications }));
    case ActionType.FETCHING_ACCOUNTS:
        return state.merge(Immutable.fromJS({ fetching: true }));
    case ActionType.FETCHED_ACCOUNTS:
        return state.merge(Immutable.fromJS({ fetching: false, accounts }));
    case ActionType.FETCHING_USERS:
        return state.merge(Immutable.fromJS({ fetching: true }));
    case ActionType.FETCHED_USERS:
        return state.merge(Immutable.fromJS({ fetching: false, users }));
    case ActionType.FETCHING_PROJECTS:
        return state.merge(Immutable.fromJS({ fetching: true }));
    case ActionType.FETCHED_PROJECTS:
        return state.merge(Immutable.fromJS({ fetching: false, projects }));
    default:
        return state;
    }
}
export default superUserReducer;
