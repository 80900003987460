import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ColorHash from 'color-hash';
import { Link } from 'react-router-dom';
import {
    Card,
    CardContent,
    CardActions,
    Button,
    IconButton,
    Typography,
    Menu,
    MenuItem,
    Grid
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

const RMListCard = props => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [ anchorEl, setAnchorEl ] = useState(null);

    const colorHash = new ColorHash();
    const { item, options } = props;
    const headerStyle = item.image
        ? {
            backgroundImage: `url(${item.image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center'
        }
        : {
            backgroundColor: colorHash.hex(item.name)
        };

    const handleMenu = (e) => {
        setAnchorEl(e.currentTarget);
        setMenuOpen(true);
    };

    const closeMenu = (e) => {
        setAnchorEl(null);
        setMenuOpen(false);
    };
    return (
        <Grid item xs={12}>
            <Card elevation={6} gutter={20} className='list card'>
                <Grid container >
                    <Grid item xs={8}>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={2}>
                                    <Link to={props.link}>
                                        <div
                                            className='list-card-image'
                                            style={headerStyle}
                                        >
                                            {!item.image
                                                ? <Typography className='list-card-header-text display-1'>
                                                    {item.name.split(' ')
                                                        .reduce((prev, curr) => (prev + curr[0]), '')
                                                        .slice(0, 3)
                                                        .toUpperCase()}
                                                </Typography> : ''}
                                        </div>
                                    </Link>
                                </Grid>
                                <Grid className='flex column' item xs={8}>
                                    <Typography className='card-name'>{item.name}</Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Grid>
                    {!props.hideReports && <Grid className='flex column' item xs={1}>
                        <Typography className='card-details' >{`${item.stats.activeReportsCount || 0} report${item.stats.activeReportsCount !== '1' ? 's' : ''}`}</Typography>
                    </Grid>}
                    <Grid className='flex column' item xs={1} >
                        <Typography className='card-details' >{`${item.stats.activeArticlesCount || 0} article${item.stats.activeArticlesCount !== '1' ? 's' : ''}`}</Typography>
                    </Grid>
                    <Grid item xs={2} >
                        <CardActions >
                            <Grid container>
                                <Grid className='flex column' item xs={6}>
                                    <Button href={props.link} >View</Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <IconButton
                                        aria-label='more'
                                        aria-controls='card-menu'
                                        aria-haspopup='true' onClick={handleMenu} >
                                        <MoreVert />
                                    </IconButton>
                                    <Menu
                                        PaperProps={{
                                            style: {
                                                width: 200
                                            }
                                        }}
                                        open={menuOpen}
                                        onClose={closeMenu}
                                        id='card-menu'
                                        anchorEl={anchorEl}
                                        transformOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right'
                                        }}
                                    >
                                        {options.map(option => (
                                            <MenuItem key={option} selected={option === 'Pyxis'} onClick={closeMenu}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Grid>
                            </Grid>
                        </CardActions>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    );
};

RMListCard.propTypes = {
    item: PropTypes.object,
    link: PropTypes.string,
    hideReports: PropTypes.bool,
    options: PropTypes.array
};
export default RMListCard;
