import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';

import nav from './nav';
import login from './login';
import projects from './projects';
import reports from './reports';
import articles from './articles';
import categories from './categories';
import outputs from './outputs';
import settings from './settings';
import superUser from './superUser';
import publications from './publications';
import keywords from './keywords';

export default combineReducers({
    form: formReducer,
    nav,
    login,
    projects,
    reports,
    articles,
    categories,
    outputs,
    settings,
    publications,
    keywords,
    superUser
});
