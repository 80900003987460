import fetch from '../util/fetch';
import { successSnack, errorSnack } from './nav';
export const FETCHED_PUBLICATIONS = Symbol('FETCHED_PUBLICATIONS');
export const FETCHING_PUBLICATIONS = Symbol('FETCHING_PUBLICATIONS');
export const FETCHED_ACCOUNTS = Symbol('FETCHED_ACCOUNTS');
export const FETCHING_ACCOUNTS = Symbol('FETCHING_ACCOUNTS');
export const FETCHED_USERS = Symbol('FETCHED_USERS');
export const FETCHING_USERS = Symbol('FETCHING_USERS');
export const FETCHED_PROJECTS = Symbol('FETCHED_PROJECTS');
export const FETCHING_PROJECTS = Symbol('FETCHING_PROJECTS');

const fetchedPublications = ({ publications }) => ({
    type: FETCHED_PUBLICATIONS,
    publications
});

const fetchingPublications = () => ({
    type: FETCHING_ACCOUNTS
});

const fetchedAccounts = ({ accounts }) => ({
    type: FETCHED_ACCOUNTS,
    accounts
});

const fetchingAccounts = () => ({
    type: FETCHING_PUBLICATIONS
});

const fetchedUsers = ({ users }) => ({
    type: FETCHED_USERS,
    users
});

const fetchingUsers = () => ({
    type: FETCHING_USERS
});

const fetchedProjects = ({ projects }) => ({
    type: FETCHED_PROJECTS,
    projects
});

const fetchingProjects = () => ({
    type: FETCHING_PROJECTS
});

export const fetchPublications = ({searchTerm}) => async dispatch => {
    dispatch(fetchingPublications());
    const publications = await fetch(`${process.env.API_ROOT}/publications?q=${searchTerm || 'A'}`);
    dispatch(fetchedPublications({publications}));
};

export const fetchAccounts = ({searchTerm}) => async dispatch => {
    dispatch(fetchingAccounts());
    const accounts = await fetch(`${process.env.API_ROOT}/super/accounts?q=${searchTerm || 'A'}`);
    dispatch(fetchedAccounts({accounts}));
};

export const fetchUsers = ({searchTerm}) => async dispatch => {
    dispatch(fetchingUsers());
    const users = await fetch(`${process.env.API_ROOT}/super/users?q=${searchTerm || 'A'}`);
    dispatch(fetchedUsers({users}));
};

export const fetchProjects = ({searchTerm}) => async dispatch => {
    dispatch(fetchingProjects());
    const projects = await fetch(`${process.env.API_ROOT}/super/projects?q=${searchTerm || 'A'}`);
    dispatch(fetchedProjects({projects}));
};

export const updatePublication = ({ id, name, country, urlId, popularity, searchTerm }) => async dispatch => {
    try {
        await fetch(`${process.env.API_ROOT}/publications/${id}`, {
            method: 'PATCH',
            body: {
                name,
                country,
                urlId,
                popularity
            }
        });
        await dispatch(fetchPublications({searchTerm}));
        dispatch(successSnack({ text: `Publication ${name} has been updated.` }));
    } catch (err) {
        dispatch(errorSnack({ text: `Failed to update publication ${name}.` }));
    }
};

export const refetchMoz = ({id, searchTerm}) => async dispatch => {
    try {
        await fetch(`${process.env.API_ROOT}/publications/${id}/update-moz`);
        dispatch(fetchPublications({searchTerm}));
        dispatch(successSnack({ text: `Successfully refetched Moz.` }));
    } catch (err) {
        dispatch(errorSnack({ text: err.response.data.message }));
    }
};

export const refetchAlexa = ({id, searchTerm}) => async dispatch => {
    try {
        await fetch(`${process.env.API_ROOT}/publications/${id}/update-alexa`);
        dispatch(fetchPublications({searchTerm}));
        dispatch(successSnack({ text: `Successfully refetched Alexa.` }));
    } catch (err) {
        dispatch(errorSnack({ text: err.response.data.message }));
    }
};

export const saveUrl = ({ pubId, imageUrl, searchTerm }) => async dispatch => {
    try {
        const response = await fetch(`${process.env.API_ROOT}/publications/${pubId}/upload-image`, {
            method: 'POST',
            body: {
                uri: imageUrl
            }
        });
        console.log('upload response', response);
        dispatch(fetchPublications({searchTerm}));
    } catch (err) {
        console.log('upload error', err);
    }
};

export const saveImage = ({ image, pubId, searchTerm }) => async dispatch => {
    await fetch(`${process.env.API_ROOT}/publications/${pubId}`, {
        method: 'PATCH',
        body: {
            image
        }
    });
    dispatch(fetchPublications({searchTerm}));
};

export const uploadImageFile = ({ file, pubId, searchTerm }) => async dispatch => {
    const reader = new FileReader();
    reader.onload = (event) => {
        dispatch(saveUrl({pubId, imageUrl: event.target.result, searchTerm}));
    };
    reader.readAsDataURL(file);
};
