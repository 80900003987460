import Immutable from 'immutable';
import * as ActionType from '../actions/categories';

const defaultState = Immutable.fromJS({
    categories: [],
    category: {}
});

function categoryReducer (state = defaultState, action) {
    const { categories, category } = action;

    switch (action.type) {
    case ActionType.FETCHED_CATEGORIES:
        return state.merge(Immutable.fromJS({ categories }));
    case ActionType.CREATED_CATEGORY:
        return state.merge(Immutable.fromJS({ category }));
    default:
        return state;
    }
}
export default categoryReducer;
