import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { fetchIssueArticles } from '../../actions/articles';
import { changePage } from '../../actions/nav';
import { Grid, Toolbar, Paper, Button, IconButton } from '@material-ui/core';
import { Info } from '@material-ui/icons';

const Issues = props => {
    const [ issues, setIssues ] = useState([]);

    useEffect(() => {
        const { id } = props.match.params;
        props.fetchIssueArticles({reportId: id});
    }, [props.match.params.id]);

    useEffect(() => {
        props.changePage({
            page: props.report.name,
            image: props.report.image,
            breadcrumbs: [
                {
                    name: 'Dashboard',
                    path: '/'
                },
                {
                    name: 'Projects',
                    path: '/projects'
                },
                {
                    name: props.project.name,
                    path: `/projects/${props.project.id}/reports`
                }
            ],
            tabs: [
                'overview', 'articles', 'issues'
            ]
        });
    }, [props.report.id, props.project.id]);

    useEffect(() => {
        const issueErrors = props.issueArticles.reduce((prev, curr) => {
            const errors = _.flatten(Object.values(curr.issues.errors));
            return prev.concat(errors);
        }, []);
        setIssues(_.uniq(issueErrors));
    }, [props.issueArticles]);

    return (
        <Grid container spacing={0}>
            <Paper exlevation={12}>
                <Toolbar className='info-bg'>
                    <IconButton className='white-text'>
                        <Info />
                    </IconButton>
                    <h2 className='headline'>Article Issues</h2>
                </Toolbar>
                {issues.map(issue => (
                    <p className='error-text' key={issue} >{issue}</p>
                ))}
                <Button color='primary'>Edit</Button>
            </Paper>
        </Grid>
    );
};

Issues.propTypes = {
    fetchIssueArticles: PropTypes.func,
    issueArticles: PropTypes.array,
    match: PropTypes.object,
    changePage: PropTypes.func,
    report: PropTypes.object,
    project: PropTypes.object
};

function mapStateToProps (state) {
    const { issueArticles, report } = state.articles.toJS();
    const { project } = state.projects.toJS();
    return { issueArticles, report, project };
}
export default connect(mapStateToProps, {fetchIssueArticles, changePage})(Issues);
