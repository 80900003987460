import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Toolbar,
    TextField,
    Paper,
    InputAdornment,
    Button,
    Grid,
    Menu,
    Divider,
    IconButton,
    MenuItem
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Search, ArrowDownward, ArrowUpward, Apps, List } from '@material-ui/icons';

const SearchBar = React.memo(props => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [ searchTerm, setSearchTerm ] = useState('');
    const MENU_OPTIONS = [ 'Date Added', 'Name' ];

    const handleMenu = (e) => {
        setAnchorEl(e.currentTarget);
        setMenuOpen(true);
    };

    const closeMenu = (e) => {
        setAnchorEl(null);
        setMenuOpen(false);
    };

    const handleClick = (e) => {
        const { id } = e.target;
        props.handleSort({value: id});
        setAnchorEl(null);
        setMenuOpen(false);
    };

    const handleSearch = (e) => {
        const { value } = e.target;
        setSearchTerm(value);
        setTimeout(() => {
            props.handleSearch({value});
        }, 100);
    };

    const handleFormat = (e, newFormat) => {
        props.handleFormat({value: newFormat});
    };

    return (
        <Grid item xs={12} >
            <Paper elevation={8} >
                <Toolbar
                    className='search-bar'
                >
                    <Grid container>
                        <Grid item sm={6}>
                            <TextField
                                label='Search'
                                placeholder='Search'
                                InputLabelProps={{shrink: true}}
                                onChange={handleSearch}
                                value={searchTerm}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <Search />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item sm={3} />
                        <Grid item sm={3}>
                            <Grid container spacing={3} alignItems='flex-end' justify='center'>
                                {props.sortBy &&
                                    <Grid item className='flex align-baseline sort-buttons'>
                                        <span>Sort:</span>
                                        <Button onClick={handleMenu} >{props.sortBy}</Button>
                                        <Menu
                                            PaperProps={{
                                                style: {
                                                    width: 200
                                                }
                                            }}
                                            open={menuOpen}
                                            onClose={closeMenu}
                                            id='search-bar-menu'
                                            anchorEl={anchorEl}
                                            transformOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right'
                                            }}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right'
                                            }}
                                        >
                                            {MENU_OPTIONS.map(option => (
                                                <MenuItem id={option} value={option} key={option} selected={option === props.sortBy} onClick={handleClick} >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                        <Divider orientation='vertical' />
                                    </Grid>}
                                <IconButton onClick={props.toggleAscending} >
                                    {props.ascending ? <ArrowUpward /> : <ArrowDownward />}
                                </IconButton>
                                <Divider orientation='vertical' />
                                <ToggleButtonGroup value={props.cardFormat} exclusive onChange={handleFormat} >
                                    <ToggleButton value='grid'>
                                        <Apps />
                                    </ToggleButton>
                                    <ToggleButton value='list'>
                                        <List />
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
            </Paper>
        </Grid>
    );
});

SearchBar.propTypes = {
    handleSort: PropTypes.func,
    handleSearch: PropTypes.func,
    toggleAscending: PropTypes.func,
    ascending: PropTypes.bool,
    sortBy: PropTypes.string,
    cardFormat: PropTypes.string,
    handleFormat: PropTypes.func

};

export default SearchBar;
