import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Toolbar, Container, Breadcrumbs, Typography, Fab, Tabs, Tab, Button } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import { Link, withRouter } from 'react-router-dom';

const MainToolBar = props => {
    const [ value, setValue ] = useState(0);

    useEffect(() => {
        const paths = props.location.pathname.split('/');
        const path = paths[paths.length - 1];
        const idx = props.tabs.indexOf(path) !== -1 ? props.tabs.indexOf(path) : 0;
        setValue(idx);
    }, [props.location.pathname, props.tabs]);

    const handleChange = (e, newValue) => {
        setValue(newValue);
        const paths = props.location.pathname.split('/');
        const newPath = `${paths.slice(0, paths.length - 1).join('/')}/${props.tabs[newValue].toLowerCase()}`;
        props.history.push(newPath);
    };

    const { image } = props;
    const toolbarStyle = image
        ? {
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3) ), url(${image})`
        }
        : {};

    return (
        <Toolbar
            className={`main-toolbar relative 
                ${props.pageTitle === 'Dashboard' || props.pageTitle === 'Projects' ? 'dashboard-toolbar' : ''}
            `}
            color='secondary'
        >
            <div className='toolbar-image-container'>
                <div className='toolbar-image' style={toolbarStyle} />
            </div>
            <Container max-width='xl' className='toolbar-content'>
                <Typography component='h3' variant='h3' className='toolbar-title display-2 font-bold'>{props.pageTitle}</Typography>
                {!props.hideBreadcrumbs && <Breadcrumbs>
                    {props.breadcrumbs.map((breadcrumb, idx) => (
                        <Link to={breadcrumb.path} color='inherit' key={`${breadcrumb.name}-${idx}`}>{breadcrumb.name}</Link>
                    ))}
                    <Typography>{props.pageTitle}</Typography>
                </Breadcrumbs>}
                {props.tabs.length
                    ? <Tabs
                        centered value={value || 0}
                        onChange={handleChange}
                    >
                        {props.tabs.map(tab => (<Tab key={tab} label={tab} />))}
                    </Tabs> : ''}
            </Container>
            {props.isArticles && <Button variant='contained' className='success-bg output-button' >Output Report</Button>}
            <Fab className='help-button' size='small' href='https://support.reportmule.com/en' target='_blank'>
                <Help />
            </Fab>
        </Toolbar>
    );
};

MainToolBar.propTypes = {
    pageTitle: PropTypes.string,
    location: PropTypes.object,
    history: PropTypes.object,
    tabs: PropTypes.array,
    image: PropTypes.string,
    hideBreadcrumbs: PropTypes.bool,
    isArticles: PropTypes.bool,
    breadcrumbs: PropTypes.array
};

export default React.memo(withRouter(React.memo(MainToolBar)));
