import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import {
    Grid,
    TextField,
    Button,
    Toolbar,
    IconButton,
    Input,
    CircularProgress,
    InputLabel
} from '@material-ui/core';
import { Add, BugReport, Edit, Computer, OpenInNew, Close } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import CustomInputs from './CustomInputs';
import {
    updateSocialStats,
    refetchArticle,
    updateArticle
} from '../../actions/articles';
import { fetchKeywordLists } from '../../actions/keywords';
import { createCategory } from '../../actions/categories';
import NewCategory from './NewCategory';
import KeywordSuggest from './KeywordSuggest';

const EditArticle = props => {
    const [ pubName, setPubName ] = useState('');
    const [ pubNames, setPubNames ] = useState([]);
    const [ title, setTitle ] = useState('');
    const [ author, setAuthor ] = useState('');
    const [ date, setDate ] = useState(null);
    const [ summary, setSummary ] = useState('');
    const [ category, setCategory ] = useState('');
    const [ categoryName, setCategoryName ] = useState('');
    const [ body, setBody ] = useState('');
    const [ newCategoryOpen, setNewCategoryOpen ] = useState(false);
    const [ keywordOpen, setKeywordOpen ] = useState(false);
    const [ customData, setCustomData ] = useState({});
    const [ view, setView ] = useState('edit');
    const { article } = props;
    const articleId = article.id;

    useEffect(() => {
        setPubName(article.publicationName || '');
        setTitle(article.title || '');
        setAuthor(article.author || '');
        setCategory(article.categoryId);
        setSummary(article.summary);
        if (article.date) {
            setDate(moment(article.date).format('YYYY-MM-DD'));
        }
        setBody(article.body || '');
        setCustomData(article.customData || {});
        props.fetchKeywordLists({projectId: article.projectId});
    }, [props.article, props.refetchCount]);

    useEffect(() => {
        const cat = props.categories.find(cat => (cat.id === article.categoryId));
        setCategoryName(cat ? cat.name : '');
    }, [props.categories]);

    useEffect(() => {
        if (props.category.id) {
            const newCat = props.categories.find(cat => (cat.id === props.category.id));
            setCategory(newCat ? newCat.id : '');
            setCategoryName(newCat ? newCat.name : '');
        }
    }, [props.category.id, props.categories]);

    const handlePubName = (e, pub) => {
        setPubName(pub);
    };

    const handleTitle = (e) => {
        setTitle(e.target.value);
    };
    const handleAuthor = (e, author) => {
        setAuthor(author);
    };
    const handleDate = (e) => {
        setDate(moment(e.target.value).format('YYYY-MM-DD'));
    };

    const handleCategory = (e, cat) => {
        const custom = Object.assign({}, customData);
        custom.category = cat.value;
        setCustomData(custom);
        // setCategory(cat ? cat.value : 0);
    };

    const handleSummary = (e) => {
        setSummary(e.target.value);
    };
    const handleBody = (e) => {
        setBody(e.target.value);
    };

    const handleSubmit = () => {
        props.updateArticle({
            articleId,
            category,
            pubName,
            title,
            author,
            date,
            summary,
            body,
            customData
        });
        props.handleCloseEdit();
    };

    const handleClose = () => {
        props.handleCloseEdit();
    };

    const handleOpenDialog = (e) => {
        props.setDialogType({type: e.target.id});
    };

    const handleRefetch = () => {
        props.refetchArticle({articleId});
    };

    const handleNew = () => {
        setNewCategoryOpen(!newCategoryOpen);
    };

    const handleKeyword = () => {
        setKeywordOpen(!keywordOpen);
    };

    const handleCreateNew = ({newCategory}) => {
        props.createCategory({name: newCategory, projectId: article.projectId});
        setCategory('');
        setNewCategoryOpen(false);
    };

    const handleKeywordSummary = ({summary}) => {
        setSummary(summary);
    };

    const handleCustom = ({ name, value }) => {
        const custom = Object.assign({}, customData);
        custom[name] = value;
        setCustomData(custom);
    };

    let justify = 'center';
    let editWidth = 10;
    let viewWidth = 6;
    let iframeClass = 'half';
    let editClass = 'show';

    if (view === 'editAndView') {
        justify = 'flex-start';
        editWidth = 6;
    } else if (view === 'view') {
        iframeClass = 'full';
        editClass = 'hide';
        viewWidth = 10;
    }

    return (
        <Grid container className='edit-article'>
            <Toolbar className='edit-article-toolbar primary-bg'>
                <Grid container justify='space-between' alignItems='center'>
                    <Grid item md={3}>
                        <Grid container>
                            <IconButton className='white-text' onClick={handleClose} >
                                <Close />
                            </IconButton>
                            <h2 className='headline'>Edit Article</h2>
                        </Grid>
                    </Grid>
                    <Grid item md={2}>
                        <Grid container justify='flex-end'>
                            <Button variant='contained' color='secondary' onClick={handleSubmit} >Submit</Button>
                            <IconButton className='white-text'>
                                <BugReport />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>
            <Grid container justify={justify} className='edit-article-main'>
                <Grid item md={editWidth} className={editClass} >
                    <Grid container spacing={1} className='edit-buttons'>
                        <Grid item>
                            <Button variant='contained' color='primary' onClick={() => props.handleSocial({articleId})}>
                                {props.socialLoading ? <CircularProgress className='button-progress' />
                                    : ' Update Social Stats'}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' color='primary' onClick={props.handleImage} >
                            Set Image
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' color='primary' id='move' onClick={handleOpenDialog}>
                            Move Article
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' color='primary' id='copy' onClick={handleOpenDialog}>
                            Copy Article
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' className='error-bg' onClick={props.handleDeleteDialog} >
                            Delete Article
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container justify='space-between'>
                        <Grid item xs={1}>
                            <IconButton className='inline-block' onClick={handleNew} >
                                <Add />
                            </IconButton>
                        </Grid>
                        <Grid item xs={9}>
                            <InputLabel shrink>Category</InputLabel>
                            <Autocomplete
                                className='edit-input'
                                id='category'
                                options={props.categories.map(category => (
                                    {title: category.name, value: category.id}
                                ))}
                                style={{width: '100%'}}
                                onChange={handleCategory}
                                getOptionLabel={option => option.title}
                                renderInput={params => <TextField {...params}
                                    placeholder={categoryName}
                                    fullWidth
                                />}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Button color='secondary' variant='contained' onClick={handleRefetch} >Refetch Article</Button>
                        </Grid>
                    </Grid>
                    <Input
                        className='edit-article-textfield edit-input edit-input'
                        disabled
                        label='URL'
                        id='url'
                        fullWidth
                        defaultValue={article.url}
                    />
                    <Grid container justify='space-between'>
                        <InputLabel shrink>Publication Name</InputLabel>
                        <Autocomplete
                            className='edit-input'
                            id='publication'
                            options={pubNames}
                            style={{width: 1000}}
                            onChange={handlePubName}
                            getOptionLabel={option => option}
                            renderInput={params => <TextField {...params} placeholder={article.publication.name} fullWidth />}
                        />
                    </Grid>
                    <Grid container justify='space-between'>
                        <TextField
                            className='edit-article-textfield edit-input'
                            label='Title *'
                            id='title'
                            value={title}
                            fullWidth
                            onChange={handleTitle}
                        />
                    </Grid>
                    <Grid container justify='space-between'>
                        <InputLabel shrink>Author</InputLabel>
                        <Autocomplete
                            className='edit-input'
                            id='author'
                            options={props.authors}
                            style={{width: 1000}}
                            onChange={handleAuthor}
                            getOptionLabel={option => option}
                            renderInput={params => <TextField {...params} placeholder={article.author} fullWidth />}
                        />
                    </Grid>
                    <Grid container justify='space-between'>
                        <TextField
                            className='edit-article-textfield edit-input'
                            label='Date *'
                            id='date'
                            type='date'
                            value={date}
                            fullWidth
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={handleDate}
                        />
                    </Grid>
                    <Grid container justify='space-between'>
                        <Button
                            variant='contained'
                            color='secondary'
                            className='edit-article-textfield edit-input'
                            onClick={handleKeyword}
                        >Suggest Summary from Keywords</Button>
                    </Grid>
                    <Grid container justify='space-between'>
                        <TextField
                            className='edit-article-textfield edit-input'
                            label='Summary *'
                            id='summary'
                            multiline
                            value={summary}
                            InputLabelProps={{
                                shrink: true
                            }}
                            fullWidth
                            onChange={handleSummary}
                        />
                    </Grid>
                    <Grid container justify='space-between'>
                        <TextField
                            className='edit-article-textfield edit-input'
                            label='Body *'
                            id='body'
                            multiline
                            value={body}
                            InputLabelProps={{
                                shrink: true
                            }}
                            fullWidth
                            onChange={handleBody}
                        />
                    </Grid>
                    <Grid container justify='space-between'>
                        <CustomInputs
                            customData={customData}
                            project={props.project}
                            handleCustom={handleCustom}
                        />
                    </Grid>
                    <Grid container justify='center' className='edit-submit-buttons' spacing={3}>
                        <Grid item>
                            <Button variant='contained' onClick={handleSubmit} >Submit</Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={props.handleCloseEdit} className='error-text' >Close</Button>
                        </Grid>
                    </Grid>
                </Grid>
                {(view === 'view' || view === 'editAndView') &&
                    <Grid item md={viewWidth} className={`iframe-container ${iframeClass}`}>
                        <iframe src={article.url} className='article-iframe' />
                    </Grid>
                }
            </Grid>
            <Toolbar className='blue-bg edit-article-footer'>
                <Grid container spacing={0} justify='center' alignItems='flex-end'>
                    <Grid item md={2}>
                        <Button onClick={() => setView('edit')}>
                            <Grid container spacing={0} direction='column' alignItems='center' justify='center'>
                                <IconButton className='footer-icon'>
                                    <Edit />
                                </IconButton>
                                <p className='center-text footer-label'>Edit</p>
                            </Grid>
                        </Button>
                    </Grid>
                    <Grid item md={2}>

                        <Button onClick={() => setView('editAndView')} >
                            <Grid container spacing={0} direction='column' alignItems='center' justify='center'>
                                <Grid item>
                                    <Grid container>
                                        <Grid item>
                                            <IconButton className='footer-icon' onClick>
                                                <Edit />
                                            </IconButton>
                                        </Grid>
                                        <Grid item>
                                            <IconButton className='footer-icon'>
                                                <Computer />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <p className='center-text footer-label'>Edit and View</p>
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>
                    <Grid item md={2}>
                        <Button onClick={() => setView('view')}>
                            <Grid container spacing={0} direction='column' alignItems='center' justify='center'>
                                <IconButton className='footer-icon'>
                                    <Computer />
                                </IconButton>
                                <p className='center-text footer-label'>View</p>
                            </Grid>
                        </Button>
                    </Grid>
                    <Button className='footer-right' href={article.url} target='_blank' endIcon={<OpenInNew />}>View in New Tab</Button>
                </Grid>
            </Toolbar>
            <NewCategory open={newCategoryOpen} handleClose={handleNew} handleSubmit={handleCreateNew} />
            <KeywordSuggest
                open={keywordOpen}
                keywordLists={props.keywordLists}
                handleClose={handleKeyword}
                title={article.title}
                body={body}
                handleSummary={handleKeywordSummary}
            />
        </Grid>
    );
};

EditArticle.propTypes = {
    article: PropTypes.object,
    project: PropTypes.object,
    category: PropTypes.object,
    updateSocialStats: PropTypes.func,
    fetchKeywordLists: PropTypes.func,
    handleCloseEdit: PropTypes.func,
    socialLoading: PropTypes.bool,
    categories: PropTypes.array,
    keywordLists: PropTypes.array,
    refetchCount: PropTypes.number,
    refetchArticle: PropTypes.func,
    createCategory: PropTypes.func,
    updateArticle: PropTypes.func,
    setDialogType: PropTypes.func,
    handleSocial: PropTypes.func,
    handleImage: PropTypes.func,
    handleDeleteDialog: PropTypes.func,
    authors: PropTypes.array,
    closeModal: PropTypes.func
};

function mapStateToProps (state) {
    const { socialLoading, refetchCount, authors } = state.articles.toJS();
    const { category } = state.categories.toJS();
    const { publications } = state.publications.toJS();
    const { keywordLists } = state.keywords.toJS();
    return { socialLoading, authors, refetchCount, category, publications, keywordLists };
}

export default React.memo(connect(mapStateToProps, {
    updateSocialStats,
    refetchArticle,
    fetchKeywordLists,
    createCategory,
    updateArticle
})(React.memo(EditArticle)));
