import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Paper, TextField, Grid, InputLabel, IconButton } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { addArticleUrls } from '../../actions/articles';
import { createCategory, fetchCategories } from '../../actions/categories';
import CustomInputs from './CustomInputs';
import NewCategory from './NewCategory';

const AddArticleDialog = props => {
    const [ urls, setUrls ] = useState([]);
    const [ options, setOptions ] = useState(false);
    const [ newCategoryOpen, setNewCategoryOpen ] = useState(false);
    const [ customData, setCustomData ] = useState({});
    const [ categories, setCategories ] = useState([]);

    useEffect(() => {
        props.fetchCategories({projectId: props.project.id, noLoad: true});
    }, [props.project.id]);

    useEffect(() => {
        setCategories(props.categories.filter(cat => (!cat.isArchived)));
    }, [props.categories]);

    const handleUrls = (e) => {
        setUrls(e.target.value.split('\n'));
    };

    const toggleOptions = () => {
        setOptions(!options);
    };

    const handleSubmit = () => {
        const uniqueUrls = _.uniq(urls);
        props.addArticleUrls({ edit: false, reportId: props.reportId, projectId: props.project.id, urls: uniqueUrls, customData });
    };

    const handleCustom = ({ name, value }) => {
        const custom = Object.assign({}, customData);
        custom[name] = value;
        setCustomData(custom);
    };

    const handleSubmitEdit = () => {
        const uniqueUrls = _.uniq(urls);
        props.addArticleUrls({ edit: true, reportId: props.reportId, projectId: props.project.id, urls: uniqueUrls, customData });
    };

    const handleCategory = (e, cat) => {
        const custom = Object.assign({}, customData);
        custom.category = cat.value;
        setCustomData(custom);
    };

    const handleNew = () => {
        setNewCategoryOpen(!newCategoryOpen);
    };

    const handleCreateNew = ({newCategory}) => {
        props.createCategory({name: newCategory, projectId: props.project.id});
        setNewCategoryOpen(false);
    };

    return (
        <Grid container justify='center' spacing={4}>
            <Grid item md={8}>
                <Paper className='add-article-dialog' elevation={6}>
                    {options &&
                    <Grid container justify='space-between'>
                        <Grid item xs={1}>
                            <IconButton className='inline-block' onClick={handleNew} >
                                <Add />
                            </IconButton>
                        </Grid>
                        <Grid item xs={11}>
                            <InputLabel shrink>Category</InputLabel>
                            <Autocomplete
                                className='edit-input'
                                id='category'
                                options={categories.map(category => (
                                    {title: category.name, value: category.id}
                                ))}
                                style={{width: '100%'}}
                                onChange={handleCategory}
                                getOptionLabel={option => option.title}
                                renderInput={params => <TextField {...params}
                                    fullWidth
                                />}
                            />
                        </Grid>
                    </Grid>}
                    <TextField
                        label='Add URLs (1 per line)*'
                        id='urls'
                        multiline
                        value={urls.join('\n')}
                        InputLabelProps={{
                            shrink: true
                        }}
                        fullWidth
                        onChange={handleUrls}
                    />
                    {options &&
                        <div className='top-25'>
                            <CustomInputs
                                customData={customData}
                                project={props.project}
                                handleCustom={handleCustom}
                            />
                        </div>}
                    <Grid container justify='center' spacing={3} className='top-25'>
                        <Grid item>
                            <Button variant='contained' color='primary' onClick={handleSubmit} >Submit</Button>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' color='secondary' onClick={handleSubmitEdit} >Submit and Edit</Button>
                        </Grid>
                        <Grid item>
                            <Button className='error-text' >Close</Button>
                        </Grid>
                    </Grid>
                    <Grid container justify='flex-end'>
                        <Button className='blue-text option-button' onClick={toggleOptions}>{options ? 'Hide' : 'Show'} Additional Options</Button>
                    </Grid>
                    <NewCategory open={newCategoryOpen} handleClose={handleNew} handleSubmit={handleCreateNew} />
                </Paper>
            </Grid>
        </Grid>
    );
};

AddArticleDialog.propTypes = {
    fetchCategories: PropTypes.func,
    addArticleUrls: PropTypes.func,
    createCategory: PropTypes.func,
    reportId: PropTypes.string,
    project: PropTypes.object,
    categories: PropTypes.array
};

export default connect(null, { addArticleUrls, createCategory, fetchCategories })(AddArticleDialog);
