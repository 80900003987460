import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import { Container } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import TopNav from './nav/TopNav';
import SideNav from './nav/SideNav';
import MainToolBar from './nav/MainToolBar';
import SnackBar from './modules/SnackBar';
import theme from '../theme';
import { checkUser } from '../actions/login';
import { snackUnloaded } from '../actions/nav';

const PageLayout = React.memo(props => {
    useEffect(() => {
        // props.checkUser();
    }, []);
    const [activeItem, setActiveItem] = useState('');
    const { children, currentUser, page, breadcrumbs, hideBreadcrumbs, image, tabs } = props;
    if (!localStorage.authKey || localStorage.authKey === 'undefined') {
        return <Redirect to={{
            pathname: '/login',
            state: {from: props.location}
        }}
        />;
    }

    useEffect(() => {
        setActiveItem(props.location.pathname.split('/')[1] || 'dashboard');
    }, [props.location.pathname]);

    const closeSnack = () => {
        props.snackUnloaded();
    };
    const paths = props.location.pathname.split('/');
    const isArticles = paths[1] === 'reports' && paths[3] === 'articles';
    return (
        <ThemeProvider theme={theme}>
            <SnackBar
                text={props.snackText}
                open={props.snackOpen}
                variant={props.snackVariant}
                link={props.snackLink}
                handleClose={closeSnack}
            />
            <TopNav currentUser={currentUser} />
            <SideNav activeItem={activeItem} setActiveItem={setActiveItem} />
            <div className='main-window'>
                <MainToolBar
                    pageTitle={page}
                    breadcrumbs={breadcrumbs}
                    hideBreadcrumbs={hideBreadcrumbs}
                    tabs={tabs}
                    image={image}
                    isArticles={isArticles}
                />
                <Container max-width='xl' className='main-container'>
                    {children}
                </Container>
            </div>
        </ThemeProvider>
    );
});

PageLayout.propTypes = {
    children: PropTypes.node.isRequired,
    currentUser: PropTypes.object,
    // checkUser: PropTypes.func,
    page: PropTypes.string,
    breadcrumbs: PropTypes.array,
    hideBreadcrumbs: PropTypes.bool,
    image: PropTypes.string,
    tabs: PropTypes.array,
    snackUnloaded: PropTypes.func,
    snackVariant: PropTypes.string,
    snackLink: PropTypes.string,
    snackText: PropTypes.string,
    snackOpen: PropTypes.bool,
    location: PropTypes.object
};

function mapStateToProps (state) {
    const { currentUser, checking } = state.login.toJS();
    const { page, image, breadcrumbs, hideBreadcrumbs, tabs, snackOpen, snackText, snackVariant, snackLink } = state.nav.toJS();
    return { currentUser, checking, page, image, breadcrumbs, tabs, hideBreadcrumbs, snackOpen, snackText, snackVariant, snackLink };
}
export default withRouter(connect(mapStateToProps, {checkUser, snackUnloaded})(PageLayout));
