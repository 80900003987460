import Immutable from 'immutable';
import * as ActionType from '../actions/reports';

const defaultState = Immutable.fromJS({
    reports: [],
    report: {},
    recentReports: [],
    newReportId: 0
});

function reportReducer (state = defaultState, action) {
    const { reports, report, recentReports, newReportId } = action;

    switch (action.type) {
    case ActionType.FETCHED_REPORTS:
        return state.merge(Immutable.fromJS({ reports }));
    case ActionType.FETCHED_REPORT:
        return state.merge(Immutable.fromJS({ report }));
    case ActionType.FETCHED_RECENT_REPORTS:
        return state.merge(Immutable.fromJS({ recentReports }));
    case ActionType.CREATED_REPORT:
        return state.merge(Immutable.fromJS({ newReportId }));
    default:
        return state;
    }
}
export default reportReducer;
