import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Modal,
    Grid,
    Paper,
    TextField,
    MenuItem,
    FormControl,
    FormControlLabel,
    Checkbox,
    Select,
    InputLabel,
    FormLabel,
    Radio,
    RadioGroup,
    IconButton,
    Button
} from '@material-ui/core';
import { SortByAlpha, ArrowUpward, ArrowDownward } from '@material-ui/icons';

const UpdateOutput = props => {
    const [ settings, setSettings ] = useState('');
    const [ index, setIndex ] = useState(false);
    const [ category, setCategory ] = useState(false);
    const [ images, setImages ] = useState(false);
    const [ header, setHeader ] = useState('');
    const [ name, setName ] = useState('');
    const [ sortBy, setSort ] = useState('');
    const [ sortBy2, setSort2 ] = useState('');
    const [ sortBy3, setSort3 ] = useState('');
    const [ descending, setDescending ] = useState(false);
    const [ descending2, setDescending2 ] = useState(false);
    const [ descending3, setDescending3 ] = useState(false);

    useEffect(() => {
        setSettings(props.output.articleDisplay || 'summary');
        setIndex(props.output.showIndex || false);
        setCategory(props.output.groupByCategory || false);
        setImages(props.output.showImages || false);
        setHeader(props.output.headerImage || 'project');
        setName(props.output.name || '');
        setSort(props.output.sort1Attr || '');
        setSort2(props.output.sort2Attr || '');
        setSort3(props.output.sort3Attr || '');
        setDescending(props.output.sort1Desc || false);
        setDescending2(props.output.sort2Desc || false);
        setDescending3(props.output.sort3Desc || false);
    }, [props.output.id]);

    const handleName = (e) => {
        const { value } = e.target;
        setName(value);
    };

    const handleSettings = (e) => {
        const { value } = e.target;
        setSettings(value);
    };
    const handleIndex = e => {
        setIndex(e.target.checked);
    };
    const handleCategory = e => {
        setCategory(e.target.checked);
    };
    const handleImages = e => {
        setImages(e.target.checked);
    };

    const handleHeader = (e) => {
        setHeader(e.target.value);
    };

    const handleSort = (e) => {
        setSort(e.target.value);
    };

    const handleSort2 = (e) => {
        setSort2(e.target.value);
    };

    const handleSort3 = (e) => {
        setSort3(e.target.value);
    };

    const handleDesc = () => {
        setDescending(!descending);
    };
    const handleDesc2 = () => {
        setDescending2(!descending2);
    };
    const handleDesc3 = () => {
        setDescending3(!descending3);
    };
    const handleSave = () => {
        const data = {
            name,
            showIndex: index,
            groupByCategory: category,
            showImages: images,
            articleDisplay: settings,
            headerImage: header,
            sort1Attr: sortBy,
            sort1Desc: descending,
            sort2Attr: sortBy2,
            sort2Desc: descending2,
            sort3Attr: sortBy3,
            sort3Desc: descending3
        };
        if (props.isAdding) {
            props.createOutput(data);
        } else {
            props.saveOutput(data);
        }
    };

    return (
        <Modal open={props.open} onClose={props.handleClose} >
            <Grid container>
                <Grid item xs={8} >
                    <Paper className='center update-output-modal'>
                        {props.isAdding && <h2>Create Output</h2>}
                        <TextField
                            required
                            fullWidth
                            label='Name'
                            id='name'
                            value={name}
                            onChange={handleName}
                        />
                        <h2>Output Settings</h2>
                        <Grid item xs={12}>
                            <FormControl margin='normal' fullWidth>
                                <InputLabel>Article Settings</InputLabel>
                                <Select
                                    id='settings'
                                    value={settings}
                                    onChange={handleSettings}
                                    fullWidth
                                    required
                                >
                                    <MenuItem value='body'>Show Body</MenuItem>
                                    <MenuItem value='summary'>Show Summary</MenuItem>
                                    <MenuItem value='none'>No Body</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value='index'
                                        onChange={handleIndex}
                                        checked={index}
                                        color='primary'
                                    />
                                }
                                label='Show Index'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value='category'
                                        onChange={handleCategory}
                                        checked={category}
                                        color='primary'
                                    />
                                }
                                label='Group By Category'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value='images'
                                        onChange={handleImages}
                                        checked={images}
                                        color='primary'
                                    />
                                }
                                label='Show Images'
                            />
                        </Grid>
                        <FormControl margin='normal' component='fieldset' fullWidth >
                            <FormLabel component='legend'>Header Image</FormLabel>
                            <RadioGroup aria-label='header' name='header' value={header} onChange={handleHeader}>
                                <FormControlLabel value='project' control={<Radio />} label='Project Image' />
                                <FormControlLabel value='report' control={<Radio />} label='Report Image' />
                                <FormControlLabel value='no' control={<Radio />} label='No Image' />
                            </RadioGroup>
                        </FormControl>
                        <Grid container alignItems='flex-end'>
                            <Grid item xs={10}>
                                <FormControl margin='normal' fullWidth >
                                    <InputLabel>Primary Sort</InputLabel>
                                    <Select
                                        id='primarySort'
                                        value={sortBy}
                                        onChange={handleSort}
                                        required
                                    >
                                        <MenuItem value='title'>Title</MenuItem>
                                        <MenuItem value='category'>Category</MenuItem>
                                        <MenuItem value='date'>Date</MenuItem>
                                        <MenuItem value='dateAdded'>Date Added</MenuItem>
                                        <MenuItem value='publication'>Publication</MenuItem>
                                        <MenuItem value='domainAuthority'>Domain Authority</MenuItem>
                                        <MenuItem value='reach'>Alexa Reach Users</MenuItem>
                                        <MenuItem value='facebookShares'>Facebook Shares</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <IconButton onClick={handleDesc} ><SortByAlpha /></IconButton>
                                <IconButton onClick={handleDesc} >{descending ? <ArrowDownward /> : <ArrowUpward />}</IconButton>
                            </Grid>
                        </Grid>
                        {sortBy
                            ? <Grid container alignItems='flex-end'>
                                <Grid item xs={10}>
                                    <FormControl margin='normal' fullWidth >
                                        <InputLabel>Secondary Sort</InputLabel>
                                        <Select
                                            id='secondarySort'
                                            value={sortBy2}
                                            onChange={handleSort2}
                                        >
                                            <MenuItem value='category'>Category</MenuItem>
                                            <MenuItem value='date'>Date</MenuItem>
                                            <MenuItem value='dateAdded'>Date Added</MenuItem>
                                            <MenuItem value='publication'>Publication</MenuItem>
                                            <MenuItem value='domainAuthority'>Domain Authority</MenuItem>
                                            <MenuItem value='reach'>Alexa Reach Users</MenuItem>
                                            <MenuItem value='facebookShares'>Facebook Shares</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton onClick={handleDesc2} ><SortByAlpha /></IconButton>
                                    <IconButton onClick={handleDesc2} >{descending2 ? <ArrowDownward /> : <ArrowUpward />}</IconButton>
                                </Grid>
                            </Grid> : ''}
                        {sortBy2
                            ? <Grid container alignItems='flex-end'>

                                <Grid item xs={10}>
                                    <FormControl margin='normal' fullWidth >
                                        <InputLabel>Tertiary Sort</InputLabel>
                                        <Select
                                            id='tertiarySort'
                                            value={sortBy3}
                                            onChange={handleSort3}
                                        >
                                            <MenuItem value='category'>Category</MenuItem>
                                            <MenuItem value='dateAdded'>Date Added</MenuItem>
                                            <MenuItem value='publication'>Publication</MenuItem>
                                            <MenuItem value='domainAuthority'>Domain Authority</MenuItem>
                                            <MenuItem value='reach'>Alexa Reach Users</MenuItem>
                                            <MenuItem value='facebookShares'>Facebook Shares</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton onClick={handleDesc3} ><SortByAlpha /></IconButton>
                                    <IconButton onClick={handleDesc3} >{descending3 ? <ArrowDownward /> : <ArrowUpward />}</IconButton>
                                </Grid>
                            </Grid> : ''}
                        <Grid container className='bottom-buttons' justify='center'>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={handleSave}
                                disabled={!name || !settings || !sortBy}
                            >Save</Button>
                            <Button onClick={props.handleClose} >Close</Button>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Modal>
    );
};

UpdateOutput.propTypes = {
    saveOutput: PropTypes.func,
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    isAdding: PropTypes.bool,
    createOutput: PropTypes.func,
    output: PropTypes.object
};

export default UpdateOutput;
