import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import {
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Paper,
    TextField,
    InputAdornment,
    LinearProgress
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { fetchAccounts } from '../../actions/superUser';

const Accounts = props => {
    const [ searchTerm, setSearchTerm ] = useState('');
    useEffect(() => {
        props.fetchAccounts({searchTerm});
    }, [searchTerm]);

    const handleSearch = (e) => {
        const term = e.target.value;
        setSearchTerm(term || 'A');
    };

    return (
        <Paper elevation={12} className='superuser-paper'>
            <TextField
                label='Search'
                placeholder='Search'
                InputLabelProps={{shrink: true}}
                onChange={handleSearch}
                value={searchTerm}
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <Search />
                        </InputAdornment>
                    )
                }}
            />
            {props.fetching &&
            <LinearProgress className='width-100' />}
            <Table>
                <TableHead>
                    <TableRow >
                        <TableCell>Name</TableCell>
                        <TableCell>Last Active</TableCell>
                        <TableCell align='right'>Primary User Name</TableCell>
                        <TableCell align='right'>Primary User Email</TableCell>
                        <TableCell align='right'>Number of Projects</TableCell>
                        <TableCell align='right'>Number of Users</TableCell>
                        <TableCell align='right'>Total Number of Articles</TableCell>
                        <TableCell align='right'>View</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                    {props.accounts.map(account => (
                        <TableRow key={account.id} >
                            <TableCell align='right'>{account.name}</TableCell>
                            <TableCell align='right'>{account.lastActive && account.lastActive !== '0000-00-00 00:00:00' ? moment(account.lastActive).format('L') : 'N/A'}</TableCell>
                            <TableCell align='right'>{account.primaryUser.real_name}</TableCell>
                            <TableCell align='right'>{account.primaryUser.email_address}</TableCell>
                            <TableCell align='right'>{account.projectTotal}</TableCell>
                            <TableCell align='right'>{account.userTotal}</TableCell>
                            <TableCell align='right'>{account.articleTotal}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
};

Accounts.propTypes = {
    fetchAccounts: PropTypes.func,
    fetching: PropTypes.bool,
    accounts: PropTypes.array
};

function mapStateToProps (state) {
    const { accounts, fetching } = state.superUser.toJS();
    return { accounts, fetching };
}

export default connect(mapStateToProps, { fetchAccounts })(Accounts);
