import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Numeral from 'numeral';
import { connect } from 'react-redux';
import {
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Paper,
    IconButton,
    TextField,
    InputAdornment,
    LinearProgress,
    Menu,
    MenuItem,
    Grid,
    Modal,
    Button
} from '@material-ui/core';
import { Edit, MoreVert, Search } from '@material-ui/icons';
import EditPublication from '../../components/superUser/EditPublication';
import Dropzone from '../../components/modules/Dropzone';
import { fetchPublications, updatePublication, refetchMoz, refetchAlexa, saveUrl, uploadImageFile } from '../../actions/superUser';
const options = [{text: 'Refetch Moz', value: 'moz'}, {text: 'Refetch Alexa', value: 'alexa'}, {text: 'Update Logo', value: 'logo'}];

const Publications = props => {
    const [ searchTerm, setSearchTerm ] = useState('');
    const [ editPub, setEditPub ] = useState({});
    const [ menuPub, setMenuPub ] = useState({});
    const [ imagePub, setImagePub ] = useState({});
    const [ anchorEl, setAnchorEl ] = useState(null);
    const [ imageUrl, setImageUrl ] = useState('');

    useEffect(() => {
        props.fetchPublications({searchTerm});
    }, [searchTerm]);

    const handleSearch = (e) => {
        const term = e.target.value;
        setSearchTerm(term || 'A');
    };

    const handleEdit = (pub) => {
        setEditPub(pub);
    };

    const handleClose = () => {
        setEditPub({});
        setMenuPub({});
        setImagePub({});
        setAnchorEl(null);
    };

    const updatePub = ({ id, name, country, urlId, popularity }) => {
        props.updatePublication({id, name, country, urlId, popularity, searchTerm});
        handleClose();
    };

    const handlePubMenu = (pub, value) => {
        if (value === 'moz') {
            props.refetchMoz({id: pub.id, searchTerm});
            handleClose();
        } else if (value === 'alexa') {
            props.refetchAlexa({id: pub.id, searchTerm});
            handleClose();
        } else if (value === 'logo') {
            setImagePub(pub);
        }
    };

    const handleImageUrl = (e) => {
        setImageUrl(e.target.value);
    };
    const handleSubmitUrl = () => {
        props.saveUrl({pubId: imagePub.id, imageUrl, searchTerm});
        handleClose();
    };

    const uploadImageFile = ({file}) => {
        props.uploadImageFile({ file, pubId: imagePub.id, searchTerm });
        handleClose();
    };

    return (
        <Paper elevation={12} className='superuser-paper'>
            <TextField
                label='Search'
                placeholder='Search'
                InputLabelProps={{shrink: true}}
                onChange={handleSearch}
                value={searchTerm}
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <Search />
                        </InputAdornment>
                    )
                }}
            />
            {props.fetching &&
            <LinearProgress className='width-100' />}
            <Table>
                <TableHead>
                    <TableRow className='super-user-table-row'>
                        <TableCell>Name</TableCell>
                        <TableCell>URL ID</TableCell>
                        <TableCell align='right'>Country</TableCell>
                        <TableCell align='right'>Domain Authority</TableCell>
                        <TableCell align='right'>Reach(Users)</TableCell>
                        <TableCell align='right'>Manual Popularity</TableCell>
                        <TableCell align='right'>Edit</TableCell>
                        <TableCell align='right'>More</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                    {props.publications.map(pub => {
                        const handleMenu = (e) => {
                            setAnchorEl(e.currentTarget);
                            setMenuPub(pub);
                        };

                        const handleSelect = (value) => {
                            handlePubMenu(pub, value);
                        };
                        return (
                            <TableRow className='super-user-table-row' key={pub.id} >
                                <TableCell>
                                    <Grid container alignItems='center'>
                                        <img src={pub.logo} className='pub-logo' />{pub.name}
                                    </Grid>
                                </TableCell>
                                <TableCell>{pub.urlId}</TableCell>
                                <TableCell>{pub.country}</TableCell>
                                <TableCell>{Math.round(pub.mozStats.domainAuthority)}</TableCell>
                                <TableCell>{Numeral(pub.alexaStats.reachUsers).format('0,0')}</TableCell>
                                <TableCell>{Numeral(pub.popularityManual).format('0,0')}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => { handleEdit(pub); }}><Edit className='success-text' /></IconButton>
                                    <EditPublication
                                        open={editPub.id === pub.id}
                                        pub={editPub}
                                        updatePub={updatePub}
                                        closeModal={handleClose}
                                    />
                                </TableCell>
                                <TableCell>
                                    <IconButton onClick={handleMenu}>
                                        <MoreVert />
                                    </IconButton>
                                    <Menu
                                        PaperProps={{
                                            style: {
                                                width: 200
                                            }
                                        }}
                                        open={menuPub.id === pub.id}
                                        onClose={handleClose}
                                        id='card-menu'
                                        anchorEl={anchorEl}
                                        transformOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right'
                                        }}
                                    >
                                        {options.map(option => (
                                            <MenuItem
                                                key={option.value}
                                                selected={false}
                                                onClick={() => { handleSelect(option.value); }}
                                            >
                                                {option.text}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                    <Modal open={imagePub.id === pub.id}>
                                        <Grid container>
                                            <Paper className='center edit-pub-modal' onClick={(e) => { e.stopPropagation(); }} >

                                                <h3>Upload Image</h3>
                                                <Grid container>
                                                    <Grid item xs={11}>
                                                        <TextField
                                                            fullWidth
                                                            label='Image URL or Paste Image'
                                                            id='imageUrl'
                                                            value={imageUrl}
                                                            onChange={handleImageUrl}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <Button variant='contained' color='primary' disabled={!imageUrl} onClick={handleSubmitUrl} >Add</Button>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Dropzone className='settings-dropzone' uploadFile={uploadImageFile} itemId={`${pub.id}`} />
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    </Modal>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Paper>
    );
};

Publications.propTypes = {
    fetchPublications: PropTypes.func,
    updatePublication: PropTypes.func,
    refetchAlexa: PropTypes.func,
    refetchMoz: PropTypes.func,
    saveUrl: PropTypes.func,
    uploadImageFile: PropTypes.func,
    fetching: PropTypes.bool,
    publications: PropTypes.array
};

function mapStateToProps (state) {
    const { publications, fetching } = state.superUser.toJS();
    return { publications, fetching };
}

export default connect(mapStateToProps, { fetchPublications, updatePublication, refetchAlexa, refetchMoz, saveUrl, uploadImageFile })(Publications);
