import Immutable from 'immutable';
import * as ActionType from '../actions/login';

const defaultState = Immutable.fromJS({
    currentUser: {},
    checking: false
});

function navReducer (state = defaultState, action) {
    const { currentUser } = action;

    switch (action.type) {
    case ActionType.USER_LOGGED_IN:
        return state.merge(Immutable.fromJS({ checking: false, currentUser }));
    case ActionType.CHECKING_USER:
        return state.merge(Immutable.fromJS({ checking: true }));
    case ActionType.USER_LOGIN_ERROR:
        return state.merge(Immutable.fromJS({ checking: false }));
    default:
        return state;
    }
}
export default navReducer;
