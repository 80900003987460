import fetch from '../util/fetch';
import { loadResults, successSnack, errorSnack } from './nav';
import { fetchProject } from './projects';
export const FETCHED_REPORTS = Symbol('FETCHED_REPORTS');
export const FETCHED_REPORT = Symbol('FETCHED_REPORT');
export const FETCHED_RECENT_REPORTS = Symbol('FETCHED_RECENT_REPORTS');
export const CREATED_REPORT = Symbol('CREATED_REPORT');

const fetchedReports = ({reports}) => ({
    type: FETCHED_REPORTS,
    reports
});

const fetchedReport = ({report}) => ({
    type: FETCHED_REPORT,
    report
});

const fetchedRecentReports = ({recentReports}) => ({
    type: FETCHED_RECENT_REPORTS,
    recentReports
});

const createdReport = ({ newReportId }) => ({
    type: CREATED_REPORT,
    newReportId
});

export const fetchReports = ({projectId}) => async (dispatch) => {
    try {
        dispatch(loadResults({isLoading: true}));
        await dispatch(fetchProject({projectId}));
        const response = await fetch(`${process.env.API_ROOT}/reports?projectId=${projectId}`);
        dispatch(fetchedReports({reports: response}));
        dispatch(loadResults({isLoading: false}));
    } catch (err) {
        console.log('err', err);
        dispatch(loadResults({isLoading: false}));

        // dispatch(loginError({errorMessage}));
    }
};

export const fetchReport = ({reportId}) => async (dispatch) => {
    try {
        dispatch(loadResults({isLoading: true}));
        // await dispatch(fetchProject({projectId}));
        const response = await fetch(`${process.env.API_ROOT}/reports/${reportId}`);
        dispatch(fetchedReport({report: response}));
        dispatch(loadResults({isLoading: false}));
    } catch (err) {
        console.log('err', err);
        dispatch(loadResults({isLoading: false}));

        // dispatch(loginError({errorMessage}));
    }
};

export const fetchRecentReports = () => async (dispatch) => {
    try {
        dispatch(loadResults({isLoading: true}));
        const response = await fetch(`${process.env.API_ROOT}/reports`);
        dispatch(fetchedRecentReports({recentReports: response}));
        dispatch(loadResults({isLoading: false}));
    } catch (err) {
        console.log('err', err);
        dispatch(loadResults({isLoading: false}));

        // dispatch(loginError({errorMessage}));
    }
};

export const saveReportName = ({ name, reportId }) => async dispatch => {
    const response = await fetch(`${process.env.API_ROOT}/reports/${reportId}`, {
        method: 'PATCH',
        body: {
            name
        }
    });
    dispatch(fetchedReport({report: response}));
    dispatch(loadResults({isLoading: false}));
};

export const saveReportUrl = ({ reportId, imageUrl }) => async dispatch => {
    try {
        const response = await fetch(`${process.env.API_ROOT}/reports/${reportId}/upload-image`, {
            method: 'POST',
            body: {
                uri: imageUrl
            }
        });
        dispatch(fetchedReport({report: response}));
        dispatch(successSnack({ text: `Image has been saved.` }));
        dispatch(loadResults({isLoading: false}));
    } catch (err) {
        dispatch(errorSnack({text: `Failed to save image.`}));
        console.log('upload error', err);
    }
};

export const saveReportImage = ({ image, reportId }) => async dispatch => {
    const response = await fetch(`${process.env.API_ROOT}/reports/${reportId}`, {
        method: 'PATCH',
        body: {
            image
        }
    });
    dispatch(fetchedReport({report: response}));
    dispatch(loadResults({isLoading: false}));
};

export const uploadReportImageFile = ({ file, reportId }) => async dispatch => {
    const reader = new FileReader();
    reader.onload = (event) => {
        dispatch(saveReportUrl({reportId, imageUrl: event.target.result}));
    };
    reader.readAsDataURL(file);
};

export const archiveReport = ({ reportId, name, projectId }) => async dispatch => {
    try {
        await fetch(`${process.env.API_ROOT}/reports/${reportId}`, {
            method: 'DELETE'
        });
        dispatch(successSnack({ text: `Report ${name} has been archived.` }));
        dispatch(fetchReports({projectId}));
    } catch (err) {
        dispatch(errorSnack({text: `Failed to archive Report ${name}.`}));
    }
};

export const createReport = ({ name, date, projectId }) => async dispatch => {
    try {
        const report = await fetch(`${process.env.API_ROOT}/reports`, {
            method: 'POST',
            body: {
                name,
                date,
                projectId
            }
        });
        dispatch(createdReport({newReportId: report.id}));
        setTimeout(() => {
            dispatch(createdReport({ newReportId: 0 }));
        }, 3000);

        dispatch(successSnack({ text: `Report ${name} has been created.` }));
    } catch (err) {
        dispatch(errorSnack({text: `Error creating report ${name}.`}));
    }
};
