import fetch from '../util/fetch';
export const FETCHED_KEYWORD_LISTS = Symbol('FETCHED_KEYWORD_LISTS');
export const FETCHED_KEYWORDS = Symbol('FETCHED_KEYWORDS');

const fetchedKeywordLists = ({keywordLists}) => ({
    type: FETCHED_KEYWORD_LISTS,
    keywordLists
});

const fetchedKeywords = ({keywords}) => ({
    type: FETCHED_KEYWORDS,
    keywords
});

export const fetchKeywordLists = ({projectId}) => async dispatch => {
    try {
        const keywordLists = await fetch(`${process.env.API_ROOT}/keyword-lists?projectId=${projectId}`);
        dispatch(fetchedKeywordLists({keywordLists}));
    } catch (err) {
        console.log('err', err);
    }
};

export const fetchKeywords = ({listId}) => async dispatch => {
    try {
        const keywords = await fetch(`${process.env.API_ROOT}/keywords?keywordListId=${listId}`);
        dispatch(fetchedKeywords({keywords}));
    } catch (err) {
        console.log('err', err);
    }
};
