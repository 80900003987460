import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import { changePage } from '../../actions/nav';
import { fetchRecentReports } from '../../actions/reports';
import { fetchRecentProjects } from '../../actions/projects';
import Card from '../../components/modules/Card';
import ListCard from '../../components/modules/ListCard';

const Dashboard = props => {
    useEffect(() => {
        props.changePage({
            page: 'Dashboard',
            image: '',
            hideBreadcrumbs: true,
            breadcrumbs: []
        });
        props.fetchRecentReports();
        props.fetchRecentProjects();
    }, []);

    useEffect(() => {
        if (props.userPrefs.items && props.userPrefs.items.indexOf('dashboard') > -1) {
            props.history.push('/projects');
        }
    }, [props.userPrefs]);
    const list = false;

    return (
        <div>
            <h2 className='headline'>Recent Reports</h2>
            {list
                ? <Grid container spacing={1}>
                    {props.recentReports.slice(0, 4).map(report => <ListCard
                        key={report.id}
                        item={report}
                        options={['Update', 'Archive Report']}
                        link={`/reports/${report.id}/articles`}
                    />)}
                </Grid>
                : <Grid container spacing={4}>
                    {props.recentReports.slice(0, 4).map(report => <Card
                        key={report.id}
                        item={report}
                        options={['Update', 'Archive Report']}
                        link={`/reports/${report.id}/articles`}
                    />)}
                </Grid>}
            <h2 className='headline'>Recent Projects</h2>
            <Link to='/projects'>
                <Grid container>
                    <Button variant='contained' color='primary' className='center-button' >All Projects</Button>
                </Grid>
            </Link>
            {list
                ? <Grid container spacing={1}>
                    {props.recentProjects.slice(0, 4).map(project => <ListCard link={`/projects/${project.id}/reports`} key={project.id} item={project} options={['Update', 'Archive Project']} />)}
                </Grid>
                : <Grid container spacing={4}>
                    {props.recentProjects.slice(0, 4).map(project => <Card link={`/projects/${project.id}/reports`} key={project.id} item={project} options={['Update', 'Archive Project']} />)}
                </Grid>}
        </div>
    );
};

Dashboard.propTypes = {
    changePage: PropTypes.func,
    fetchRecentProjects: PropTypes.func,
    fetchRecentReports: PropTypes.func,
    recentProjects: PropTypes.array,
    userPrefs: PropTypes.object,
    recentReports: PropTypes.array,
    history: PropTypes.object
};

function mapStateToProps (state) {
    const { recentReports } = state.reports.toJS();
    const { recentProjects } = state.projects.toJS();
    const { userPrefs } = state.settings.toJS();
    return { recentReports, recentProjects, userPrefs };
}

export default withRouter(connect(mapStateToProps, {changePage, fetchRecentReports, fetchRecentProjects})(Dashboard));
