import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ColorHash from 'color-hash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
    Card,
    CardContent,
    CardActions,
    Button,
    IconButton,
    Typography,
    Divider,
    Menu,
    MenuItem,
    Grid,
    Chip
} from '@material-ui/core';
import { MoreVert, Facebook, Twitter, ExitToApp } from '@material-ui/icons';

const ArticleCard = props => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [ anchorEl, setAnchorEl ] = useState(null);

    const colorHash = new ColorHash();
    const { item, options } = props;
    const headerStyle = item.image
        ? {
            backgroundImage: `url(${item.image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center'
        }
        : {
            backgroundColor: colorHash.hex(item.title)
        };

    const handleMenu = (e) => {
        setAnchorEl(e.currentTarget);
        setMenuOpen(true);
    };

    const closeMenu = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    };

    const handleEdit = () => {
        props.handleEdit({articleId: item.id});
    };

    const selectOption = (idx) => {
        props.handleOption({articleId: item.id, option: idx});
        closeMenu();
    };

    return (
        <Grid item sm={6} xs={12} md={4} lg={3}>
            <Card elevation={6} gutter={20} className='card'>
                <CardContent className='card-content'>
                    <p className=' body-2 blue-text' >{item.user ? item.user.realName : ''}<span className='black-text'> added </span>{moment(item.added).fromNow()}</p>
                    <Link to={props.link}>
                        <div
                            className='card-top'
                            style={headerStyle}
                        >
                            {!item.image
                                ? <Typography className='card-header-text'>
                                    {(item.title || '').split(' ')
                                        .reduce((prev, curr) => (prev + curr[0]), '')
                                        .slice(0, 3)
                                        .toUpperCase()}
                                </Typography> : ''}
                        </div>
                    </Link>
                    <Typography className='card-details'>{`${item.author || ''}/${item.date || ''}`}</Typography>
                    <Typography className='card-title'>{item.title}</Typography>
                    <Chip
                        icon={<Facebook className='white-text' />}
                        label={item.socialStats ? item.socialStats.facebook_total : 0}
                        className='facebook-chip white-text'
                    />
                    <Chip
                        icon={<Twitter className='white-text' />}
                        label={item.socialStats ? item.socialStats.twitter : 0}
                        className='twitter-chip white-text'
                    />
                    <Divider />
                    <Grid container>
                        <Grid item xs={2}>
                            <img className='article-publication-image width-100 height-100' src={item.publication ? item.publication.logo : ''} />
                        </Grid>
                        <Grid item xs={10}>
                            <p className='body-2'>{item.publicationName}</p>
                        </Grid>
                    </Grid>
                    <p className='body-2'>{`Reach (users): ${item.publication.alexaStats.reachUsers}`}</p>
                </CardContent>
                <Divider />
                <CardActions>
                    <Grid container>
                        <Grid item xs={4}>
                            <Button onClick={handleEdit} >Edit</Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button href={item.url} endIcon={<ExitToApp />}target='_blank' >View</Button>
                        </Grid>
                        <Grid item xs={4}>
                            <IconButton
                                aria-label='more'
                                aria-controls='card-menu'
                                aria-haspopup='true' onClick={handleMenu} >
                                <MoreVert />
                            </IconButton>
                            <Menu
                                PaperProps={{
                                    style: {
                                        width: 200
                                    }
                                }}
                                open={menuOpen}
                                onClose={closeMenu}
                                id='card-menu'
                                anchorEl={anchorEl}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                            >
                                {options.map((option, idx) => (
                                    <MenuItem
                                        key={option}
                                        selected={false}
                                        onClick={(e) => selectOption(idx)}
                                    >
                                        {option}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </Grid>
    );
};

ArticleCard.propTypes = {
    item: PropTypes.object,
    handleEdit: PropTypes.func,
    handleOption: PropTypes.func,
    link: PropTypes.string,
    options: PropTypes.array
};
export default ArticleCard;
