
export const LOADING_RESULTS = Symbol('LOADING_RESULTS');
export const STOP_LOADING_RESULTS = Symbol('STOP_LOADING_RESULTS');
export const PAGE_CHANGED = Symbol('PAGE_CHANGED');
export const SNACK_LOADED = Symbol('SNACK_LOADED');
export const SNACK_UNLOADED = Symbol('SNACK_UNLOADED');

const loadingResults = () => ({
    type: LOADING_RESULTS
});

const stopLoadingResults = () => ({
    type: STOP_LOADING_RESULTS
});

const pageChanged = ({ page, image, breadcrumbs, hideBreadcrumbs, tabs }) => ({
    type: PAGE_CHANGED,
    page,
    image,
    breadcrumbs,
    hideBreadcrumbs,
    tabs
});

const snackLoaded = ({ snackText, snackVariant, snackLink }) => ({
    type: SNACK_LOADED,
    snackVariant,
    snackText,
    snackLink
});

export const snackUnloaded = () => ({
    type: SNACK_UNLOADED
});

export const loadResults = ({ isLoading }) => dispatch => {
    if (isLoading) {
        dispatch(loadingResults());
    } else {
        dispatch(stopLoadingResults());
    }
};

export const changePage = ({page, image, breadcrumbs, hideBreadcrumbs, tabs}) => dispatch => {
    if (hideBreadcrumbs === undefined) {
        hideBreadcrumbs = false;
    }
    dispatch(pageChanged({page, image, breadcrumbs, hideBreadcrumbs, tabs}));
};

export const successSnack = ({text, link}) => dispatch => {
    dispatch(loadSnack({ snackText: text, snackVariant: 'success', snackLink: link || '' }));
};

export const errorSnack = ({text}) => dispatch => {
    dispatch(loadSnack({ snackText: text, snackVariant: 'error', snackLink: '' }));
};

export const loadSnack = ({ snackText, snackVariant, snackLink }) => dispatch => {
    dispatch(snackLoaded({ snackText, snackVariant, snackLink }));
    setTimeout(() => {
        dispatch(snackUnloaded());
    }, 5000);
};
