import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import {
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Paper,
    TextField,
    InputAdornment,
    LinearProgress,
    Grid
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { fetchUsers } from '../../actions/superUser';

const Users = props => {
    const [ searchTerm, setSearchTerm ] = useState('');

    useEffect(() => {
        props.fetchUsers({searchTerm});
    }, [searchTerm]);

    const handleSearch = (e) => {
        const term = e.target.value;
        setSearchTerm(term || 'A');
    };

    return (
        <Paper elevation={12} className='superuser-paper'>
            <TextField
                label='Search'
                placeholder='Search'
                InputLabelProps={{shrink: true}}
                onChange={handleSearch}
                value={searchTerm}
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <Search />
                        </InputAdornment>
                    )
                }}
            />
            {props.fetching &&
            <LinearProgress className='width-100' />}
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell align='right'>Account</TableCell>
                        <TableCell align='right'>Current Permission Status</TableCell>
                        <TableCell align='right'>First Sign In Date</TableCell>
                        <TableCell align='right'>Most Recent Activity Date</TableCell>
                        <TableCell align='right'>View</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                    {props.users.map(user => (
                        <TableRow key={user.id} >
                            <TableCell>
                                <Grid container alignItems='center'>
                                    {user.realName}
                                </Grid>
                            </TableCell>
                            <TableCell>{user.emailAddress}</TableCell>
                            <TableCell>{user.account.account_name}</TableCell>
                            <TableCell>{user.activeRoleNames.length ? user.activeRoleNames[0] : ''}</TableCell>
                            <TableCell>{moment(user.firstSignIn).format('L')}</TableCell>
                            <TableCell>{user.lastLogin ? moment(user.lastLogin).format('L') : 'N/A'}</TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
};

Users.propTypes = {
    fetchUsers: PropTypes.func,
    fetching: PropTypes.bool,
    users: PropTypes.array
};

function mapStateToProps (state) {
    const { users, fetching } = state.superUser.toJS();
    return { users, fetching };
}

export default connect(mapStateToProps, { fetchUsers })(Users);
