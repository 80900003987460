import Immutable from 'immutable';
import * as ActionType from '../actions/publications';

const defaultState = Immutable.fromJS({
    publications: []
});

function articleReducer (state = defaultState, action) {
    const { publications } = action;

    switch (action.type) {
    case ActionType.FETCHED_PUBLICATIONS:
        return state.merge(Immutable.fromJS({ publications }));
    default:
        return state;
    }
}
export default articleReducer;
