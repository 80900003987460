import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Paper, Grid, Button } from '@material-ui/core';
import ArchivedCategory from './ArchivedCategory';

const ArchivedCategories = props => {
    return (
        <Modal open={props.open} onClose={props.handleClose} >
            <Grid container>
                <Grid item xs={10} >
                    <Paper className='center archive-modal'>
                        <h2 className='headline center-text'>Archived Categories</h2>
                        <Grid container spacing={2} >
                            {props.categories.map(category => (
                                <ArchivedCategory
                                    category={category}
                                    projectId={props.projectId}
                                    handleClose={props.handleClose}
                                />))}
                        </Grid>
                        <Button onClick={props.handleClose}>Close</Button>
                    </Paper>
                </Grid>
            </Grid>
        </Modal>
    );
};

ArchivedCategories.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    categories: PropTypes.array,
    projectId: PropTypes.string
};

export default ArchivedCategories;
