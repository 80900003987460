import React from 'react';
import PropTypes from 'prop-types';
import ColorHash from 'color-hash';
import {
    Card,
    CardContent,
    CardActions,
    Typography,
    Divider,
    Grid,
    Switch
} from '@material-ui/core';

const UserPreferenceCard = props => {
    const colorHash = new ColorHash();
    const headerStyle = props.image
        ? {
            backgroundImage: `url(${props.image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center'
        }
        : {
            backgroundColor: colorHash.hex(props.title)
        };

    const handleCheck = (e) => {
        props.handleToggle(props.value, !props.enabled, props.title);
    };

    return (
        <Grid item sm={6} xs={12} md={4} lg={3}>
            <Card elevation={6} gutter={20} className='card'>
                <CardContent>
                    <div
                        className='card-top'
                        style={headerStyle}
                    >
                        {!props.image
                            ? <Typography className='card-header-text'>
                                {props.title.split(' ')
                                    .reduce((prev, curr) => (prev + curr[0]), '')
                                    .slice(0, 3)
                                    .toUpperCase()}
                            </Typography> : ''}
                    </div>
                    <Typography className='card-name'>{props.title}</Typography>
                    <Typography className='card-details' >{props.description}</Typography>
                </CardContent>
                <Divider />
                <CardActions className='flex' >
                    <Switch
                        checked={props.enabled}
                        onClick={handleCheck}
                        value={props.value}
                    />
                </CardActions>
            </Card>
        </Grid>
    );
};

UserPreferenceCard.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    enabled: PropTypes.bool,
    handleToggle: PropTypes.func,
    value: PropTypes.string
};
export default UserPreferenceCard;
