import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Card from '../../components/modules/Card';
import ListCard from '../../components/modules/ListCard';
import ConfirmDialog from '../../components/modules/ConfirmDialog';
import SearchBar from '../../components/projects/SearchBar';
import SettingsModal from '../../components/projects/SettingsModal';
import { fetchProjects, archiveProject } from '../../actions/projects';
import { changePage } from '../../actions/nav';
import Loading from '../../components/modules/Loading';
import { Grid, Modal, Paper } from '@material-ui/core';

const ProjectsContainer = React.memo(props => {
    const [ ascending, setAscending ] = useState(true);
    const [ sortBy, setSortBy ] = useState('Date Added');
    const [ searchTerm, setSearchTerm ] = useState('');
    const [ cardFormat, setCardFormat ] = useState('grid');
    const [ projects, setProjects ] = useState([]);
    const [ updateId, setUpdateId ] = useState(0);
    const [ archiveId, setArchiveId ] = useState(0);
    const options = ['Update', 'Archive Project'];

    useEffect(() => {
        props.fetchProjects();
        props.changePage({ page: 'Projects', image: '', breadcrumbs: [{name: 'Dashboard', path: '/'}] });
    }, []);

    useEffect(() => {
        setCardFormat(props.gridPrefs.cardStyle_projects || 'grid');
    }, [props.gridPrefs]);

    useEffect(() => {
        const sortedProjects = props.projects.filter(project => {
            if (!searchTerm) {
                return true;
            }
            return project.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
        }).sort((a, b) => {
            const first = ascending ? a : b;
            const second = ascending ? b : a;
            if (sortBy === 'Date Added') {
                if (first.id === null || first.id < second.id) {
                    return -1;
                }
                return 1;
            } else {
                if (first.name.toLowerCase() < second.name.toLowerCase()) {
                    return -1;
                }
                return 1;
            }
        });
        setProjects(sortedProjects);
    }, [props.projects, searchTerm, ascending, sortBy]);

    const handleSort = ({value}) => {
        setSortBy(value);
    };

    const handleSearch = ({value}) => {
        console.log('vakue', value);
        setSearchTerm(value);
    };

    const handleFormat = ({value}) => {
        setCardFormat(value);
    };

    const handleAscending = () => {
        setAscending(!ascending);
    };

    const handleSelect = ({idx, id}) => {
        const option = options[idx];
        if (option === 'Update') {
            setUpdateId(id);
        } else if (option === 'Archive Project') {
            setArchiveId(id);
        }
    };

    const handleClose = () => {
        setUpdateId(0);
    };

    const handleCloseDialog = () => {
        setArchiveId(0);
    };
    const handleArchive = () => {
        props.archiveProject({projectId: archiveId});
        setArchiveId(0);
    };

    if (props.loading) {
        return <Loading />;
    }
    return (
        <Grid container spacing={0}>
            <SearchBar
                ascending={ascending}
                toggleAscending={handleAscending}
                sortBy={sortBy}
                cardFormat={cardFormat}
                // searchTerm={searchTerm}
                handleSort={handleSort}
                handleSearch={handleSearch}
                handleFormat={handleFormat}
            />
            {cardFormat === 'list'
                ? <Grid container spacing={1}>
                    {projects.map(project =>
                        <ListCard
                            link={`/projects/${project.id}/reports`}
                            key={project.id}
                            item={project}
                            options={options}
                            handleSelect={handleSelect}
                        />)}
                </Grid>
                : <Grid container spacing={4}>
                    {projects.map(project => (
                        <Card
                            link={`/projects/${project.id}/reports`}
                            key={project.id}
                            item={project}
                            options={options}
                            handleSelect={handleSelect}
                        />))}
                </Grid>}
            <Modal open={!!updateId} onClose={handleClose} >
                <Grid container>
                    <Grid item xs={10} >
                        <Paper className='center archive-modal'>
                            <SettingsModal item={props.projects.find(project => (project.id === updateId))} type='project' />
                        </Paper>
                    </Grid>
                </Grid>
            </Modal>
            <ConfirmDialog
                open={!!archiveId}
                text={archiveId ? `This will archive "${props.projects.find(project => (project.id === archiveId)).name}."` : ''}
                handleClose={handleCloseDialog}
                handleConfirm={handleArchive}
            />
        </Grid>);
});

ProjectsContainer.propTypes = {
    fetchProjects: PropTypes.func,
    gridPrefs: PropTypes.object,
    changePage: PropTypes.func,
    archiveProject: PropTypes.func,
    loading: PropTypes.bool,
    projects: PropTypes.array
};

function mapStateToProps (state) {
    const { projects, ascending } = state.projects.toJS();
    const { gridPrefs } = state.settings.toJS();
    const { loading } = state.nav.toJS();
    return { projects, ascending, loading, gridPrefs };
}
export default React.memo(connect(mapStateToProps, { fetchProjects, changePage, archiveProject })(ProjectsContainer));
