import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import ArticleCard from '../../components/articles/ArticleCard';
import EditArticle from '../../components/articles/EditArticle';
import ArticleListCard from '../../components/articles/ArticleListCard';
import AddArticleDialog from '../../components/articles/AddArticleDialog';
import ArticleDialog from '../../components/articles/ArticleDialog';
import Loading from '../../components/modules/Loading';
import SearchBar from '../../components/projects/SearchBar';
import ConfirmDialog from '../../components/modules/ConfirmDialog';
import ImageModal from '../../components/articles/ImageModal';
import { fetchReports, saveReportUrl } from '../../actions/reports';
import { fetchProjects, saveUrl } from '../../actions/projects';
import { fetchPublications } from '../../actions/publications';
import { fetchCategories } from '../../actions/categories';
import {
    fetchArticles,
    fetchArticle,
    moveArticle,
    copyArticle,
    deleteArticle,
    fetchAuthors
} from '../../actions/articles';
import { changePage } from '../../actions/nav';
import { Grid, Button, Paper, Fab } from '@material-ui/core';
import { ArrowUpward } from '@material-ui/icons';

const OPTIONS = [
    'Update Social Stats',
    'Set Image',
    'Set as Report Image',
    'Set as Project Image',
    'Move Article',
    'Copy Article',
    'Delete Article'
];

const Articles = props => {
    const [ ascending, setAscending ] = useState(true);
    const [ editing, setEdit ] = useState(false);
    const [ isAdding, setAdding ] = useState(false);
    const [ article, setArticle ] = useState(null);
    const [ sortBy, setSortBy ] = useState('Date Added');
    const [ searchTerm, setSearchTerm ] = useState('');
    const [ cardFormat, setCardFormat ] = useState('grid');
    const [ dialogOpen, setDialogOpen ] = useState(false);
    const [ dialogType, setDialogType ] = useState('move');
    const [ imageModalOpen, setImageModal ] = useState(false);
    const [ deleteDialogOpen, setDeleteDialogOpen ] = useState(false);
    const [ categories, setCategories ] = useState([]);
    const reportId = props.report.id;

    useEffect(() => {
        props.fetchProjects();
        props.fetchAuthors();
        props.fetchPublications();
    }, []);

    useEffect(() => {
        setCardFormat(props.gridPrefs.cardStyle_articles || 'grid');
    }, [props.gridPrefs]);

    useEffect(() => {
        const { id } = props.match.params;
        props.fetchArticles({reportId: id});
    }, [props.match.params.id]);

    useEffect(() => {
        if (props.updatedArticles) {
            const { id } = props.match.params;
            setAdding(false);
            props.fetchArticles({reportId: id});
        }
    }, [props.updatedArticles]);

    useEffect(() => {
        props.changePage({
            page: props.report.name,
            image: props.report.image,
            breadcrumbs: [
                {
                    name: 'Dashboard',
                    path: '/'
                },
                {
                    name: 'Projects',
                    path: '/projects'
                },
                {
                    name: props.project.name,
                    path: `/projects/${props.project.id}/reports`
                }
            ],
            tabs: [
                'overview', 'articles', 'issues'
            ]
        });
        if (props.project.id) {
            props.fetchReports({projectId: props.project.id});
            props.fetchCategories({projectId: props.project.id, noLoad: true});
        }
    }, [props.report.id, props.project.id]);

    useEffect(() => {
        if (props.article.id) {
            setArticle(props.article);
            setEdit(true);
            setAdding(false);
        }
    }, [props.article.id]);

    useEffect(() => {
        setCategories(props.categories.filter(cat => (!cat.isArchived)));
    }, [props.categories]);

    const sortArticles = () => {
        return props.articles.filter(article => {
            if (!searchTerm) {
                return true;
            }
            return article.title.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
        }).sort((a, b) => {
            const first = ascending ? a : b;
            const second = ascending ? b : a;
            if (sortBy === 'Date Added') {
                if (first.added === null || moment(first.added) < moment(second.added)) {
                    return -1;
                }
                return 1;
            } else {
                if (first.title.toLowerCase() < second.title.toLowerCase()) {
                    return -1;
                }
                return 1;
            }
        });
    };

    const handleSort = ({value}) => {
        setSortBy(value);
    };

    const handleSearch = ({value}) => {
        setSearchTerm(value);
    };

    const handleFormat = ({value}) => {
        setCardFormat(value);
    };

    const handleAscending = () => {
        setAscending(!ascending);
    };

    const handleEdit = ({ articleId }) => {
        setArticle(props.articles.find(art => art.id === articleId));
        setEdit(true);
    };

    const handleCloseEdit = () => {
        setEdit(false);
    };

    const handleAdd = () => {
        setAdding(!isAdding);
    };

    const handleSocial = (article) => {
        props.updateSocialStats({articleId: article.id, reportId});
    };

    const moveArticle = ({ reportId, reportName, categoryId }) => {
        props.moveArticle({ reportId, reportName, categoryId, article });
        setDialogOpen(false);
        handleCloseEdit();
    };

    const copyArticle = ({ reportId, reportName, categoryId }) => {
        props.copyArticle({ reportId, reportName, categoryId, article });
        setDialogOpen(false);
        handleCloseEdit();
    };

    const handleDialogType = ({ type }) => {
        setDialogType(type);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleDeleteDialog = () => {
        setDeleteDialogOpen(!deleteDialogOpen);
    };

    const handleConfirmDelete = () => {
        props.deleteArticle({article, reportId});
        setDeleteDialogOpen(false);
        handleCloseEdit();
    };

    const handleImage = () => {
        setImageModal(true);
    };

    const handleCloseImage = () => {
        setImageModal(false);
    };

    const handleMove = () => {
        handleDialogType({type: 'move'});
    };

    const handleCopy = () => {
        handleDialogType({type: 'copy'});
    };

    const handleReportImage = (article) => {
        props.saveReportUrl({reportId, imageUrl: article.image});
    };

    const handleProjectImage = (article) => {
        props.saveUrl({projectId: props.project.id, imageUrl: article.image});
    };

    const handleOption = ({option, articleId}) => {
        const article = props.articles.find(art => art.id === articleId);
        setArticle(article);
        const funcs = [
            handleSocial,
            handleImage,
            handleReportImage,
            handleProjectImage,
            handleMove,
            handleCopy,
            handleDeleteDialog
        ];
        funcs[option](article);
    };

    const articles = sortArticles();
    if (props.loading) {
        return <Loading />;
    }

    let content = (
        <Grid container justify='center'>
            <Grid item xs={12} md={6}>
                <Paper className='relative add-article-paper' elevation={6}>
                    <h2 className='headline add-article-header' >Add Articles to your Report.</h2>
                    <Fab color='secondary' className='article-fab'>
                        <ArrowUpward />
                    </Fab>
                </Paper>
            </Grid>
        </Grid>
    );

    if (editing) {
        content = <EditArticle
            project={props.project}
            report={props.report}
            article={article}
            handleCloseEdit={handleCloseEdit}
            handleDeleteDialog={handleDeleteDialog}
            handleImage={handleImage}
            categories={categories}
            setDialogType={handleDialogType}
        />;
    } else if (articles.length || searchTerm) {
        // content = <EditArticle article={articles[0]} projectId={props.project.id} open />;
        content = cardFormat === 'list'
            ? <Grid container spacing={1}>
                {articles.map(article => <ArticleListCard
                    key={article.id}
                    item={article}
                    options={OPTIONS}
                    handleOption={handleOption}
                    handleEdit={handleEdit}
                    link='#'
                />)}
            </Grid>
            : <Grid container spacing={4}>
                {articles.map(article => <ArticleCard
                    key={article.id}
                    item={article}
                    options={OPTIONS}
                    handleOption={handleOption}
                    handleEdit={handleEdit}
                    link='#'
                />)}
            </Grid>;
    }
    return (
        <Grid container spacing={0}>
            {isAdding
                ? <AddArticleDialog reportId={props.report.id} project={props.project} categories={props.categories} />
                : <Grid container >
                    <Button color='primary' variant='contained' className='center-button ' onClick={handleAdd} >Add Articles</Button>
                </Grid>}

            {articles.length || searchTerm ? <SearchBar
                ascending={ascending}
                toggleAscending={handleAscending}
                sortBy={sortBy}
                cardFormat={cardFormat}
                searchTerm={searchTerm}
                handleSort={handleSort}
                handleSearch={handleSearch}
                handleFormat={handleFormat}
            /> : ''}
            {content}
            <ArticleDialog
                open={dialogOpen}
                type={dialogType}
                article={article || {}}
                categories={categories}
                projects={props.projects}
                reports={props.reports}
                handleClose={handleCloseDialog}
                moveArticle={moveArticle}
                copyArticle={copyArticle}
            />
            <ConfirmDialog
                text={`This will delete "${article ? article.title : ''}" in ${props.report.name || ''}.`}
                handleClose={handleDeleteDialog}
                handleConfirm={handleConfirmDelete}
                open={deleteDialogOpen}
            />
            <ImageModal open={imageModalOpen} handleClose={handleCloseImage} item={article || {}} />
        </Grid>
    );
};

Articles.propTypes = {
    fetchArticles: PropTypes.func,
    fetchReports: PropTypes.func,
    fetchProjects: PropTypes.func,
    fetchAuthors: PropTypes.func,
    fetchCategories: PropTypes.func,
    fetchPublications: PropTypes.func,
    articles: PropTypes.array,
    match: PropTypes.object,
    changePage: PropTypes.func,
    report: PropTypes.object,
    gridPrefs: PropTypes.object,
    project: PropTypes.object,
    article: PropTypes.object,
    categories: PropTypes.array,
    deleteArticle: PropTypes.func,
    updateSocialStats: PropTypes.func,
    moveArticle: PropTypes.func,
    copyArticle: PropTypes.func,
    saveReportUrl: PropTypes.func,
    saveUrl: PropTypes.func,
    reports: PropTypes.array,
    projects: PropTypes.array,
    loading: PropTypes.bool,
    updatedArticles: PropTypes.bool
};

function mapStateToProps (state) {
    const { articles, loading, ascending, report, updatedArticles, article } = state.articles.toJS();
    const { categories } = state.categories.toJS();
    const { reports } = state.reports.toJS();
    const { project, projects } = state.projects.toJS();
    const { gridPrefs } = state.settings.toJS();
    return {
        articles,
        ascending,
        report,
        project,
        loading,
        gridPrefs,
        updatedArticles,
        article,
        categories,
        projects,
        reports
    };
}
export default React.memo(connect(mapStateToProps, {
    fetchArticles,
    fetchArticle,
    fetchReports,
    fetchProjects,
    fetchAuthors,
    fetchCategories,
    fetchPublications,
    changePage,
    moveArticle,
    deleteArticle,
    copyArticle,
    saveReportUrl,
    saveUrl
})(React.memo(Articles)));
