import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ColorHash from 'color-hash';
import { Link } from 'react-router-dom';
import {
    Card,
    CardContent,
    CardActions,
    Button,
    IconButton,
    Typography,
    Divider,
    Menu,
    MenuItem,
    Grid
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

const RMCard = props => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [ selectedIndex, setSelectedIndex ] = useState(-1);
    const [ anchorEl, setAnchorEl ] = useState(null);

    const colorHash = new ColorHash();
    const { item, options } = props;
    const headerStyle = item.image
        ? {
            backgroundImage: `url(${item.image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center'
        }
        : {
            backgroundColor: colorHash.hex(item.name)
        };

    const handleMenu = (e) => {
        setAnchorEl(e.currentTarget);
        setMenuOpen(true);
    };

    const closeMenu = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    };

    const handleSelect = (idx) => {
        setSelectedIndex(idx);
        props.handleSelect({idx, id: item.id});
        closeMenu();
    };

    return (
        <Grid item sm={6} xs={12} md={4} lg={3}>
            <Card elevation={6} gutter={20} className='card'>
                <CardContent className='card-content'>
                    <Link to={props.link}>
                        <div
                            className='card-top'
                            style={headerStyle}
                        >
                            {props.flagText &&
                                <Card>
                                    <div className='card-flag'>{props.flagText}</div>
                                </Card>
                            }
                            {!item.image
                                ? <Typography className='card-header-text'>
                                    {item.name.split(' ')
                                        .reduce((prev, curr) => (prev + curr[0]), '')
                                        .slice(0, 3)
                                        .toUpperCase()}
                                </Typography> : ''}
                        </div>
                    </Link>
                    <Typography className='card-name'>{item.name}</Typography>
                    {item.stats &&
                        <div>
                            {!props.hideReports && <Typography className='card-details' >{`${item.stats.activeReportsCount || 0} report${item.stats.activeReportsCount !== '1' ? 's' : ''}`}</Typography>}
                            <Typography className='card-details' >{`${item.stats.activeArticlesCount || 0} article${item.stats.activeArticlesCount !== '1' ? 's' : ''}`}</Typography>
                        </div>}
                </CardContent>
                <Divider />
                <CardActions className='flex relative' >
                    <Button href={props.link} >View</Button>
                    <IconButton
                        aria-label='more'
                        aria-controls='card-menu'
                        aria-haspopup='true' onClick={handleMenu}
                        disabled={!options.length}
                    >
                        <MoreVert />
                    </IconButton>
                    <Menu
                        PaperProps={{
                            style: {
                                width: 200
                            }
                        }}
                        open={menuOpen}
                        onClose={closeMenu}
                        id='card-menu'
                        anchorEl={anchorEl}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                    >
                        {options.map((option, idx) => (
                            <MenuItem
                                key={option}
                                selected={idx === selectedIndex}
                                onClick={(e) => { handleSelect(idx); }}
                            >
                                {option}
                            </MenuItem>
                        ))}
                    </Menu>
                </CardActions>
            </Card>
        </Grid>
    );
};

RMCard.propTypes = {
    link: PropTypes.string,
    hideReports: PropTypes.bool,
    item: PropTypes.object,
    handleSelect: PropTypes.func,
    flagText: PropTypes.string,
    options: PropTypes.array
};
export default RMCard;
