import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogContent,
    DialogActions,
    TextField,
    Button
} from '@material-ui/core';

const NewCategory = React.memo(props => {
    const [ newCategory, setCategory ] = useState('');

    const handleChange = e => {
        setCategory(e.target.value);
    };

    const handleSubmit = () => {
        props.handleSubmit({newCategory});
    };
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby='form-dialog-title'
            fullWidth
            maxWidth='md'
        >
            <DialogContent>
                <TextField
                    autoFocus
                    margin='dense'
                    id='categoryName'
                    label='Category Name'
                    type='text'
                    fullWidth
                    error={!newCategory}
                    helperText='Field is required'
                    value={newCategory}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit} variant='contained' color='primary' disabled={!newCategory}>Submit</Button>
                <Button onClick={props.handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
});

NewCategory.propTypes = {
    handleSubmit: PropTypes.func,
    open: PropTypes.bool,
    handleClose: PropTypes.func
};

export default NewCategory;
