import Immutable from 'immutable';
import * as ActionType from '../actions/projects';

const defaultState = Immutable.fromJS({
    projects: [],
    recentProjects: [],
    project: {
        categories: [],
        customFields: []
    }
});

function projectReducer (state = defaultState, action) {
    const { projects, project, recentProjects } = action;

    switch (action.type) {
    case ActionType.FETCHED_PROJECTS:
        return state.merge(Immutable.fromJS({ projects }));
    case ActionType.FETCHED_RECENT_PROJECTS:
        return state.merge(Immutable.fromJS({ recentProjects }));
    case ActionType.FETCHED_PROJECT:
        return state.merge(Immutable.fromJS({ project }));
    default:
        return state;
    }
}
export default projectReducer;
