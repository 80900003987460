import fetch from '../util/fetch';
import { storedGridPrefs, storedUserPrefs } from './settings';
export const USER_LOGGED_IN = Symbol('USER_LOGGED_IN');
export const USER_LOGIN_ERROR = Symbol('USER_LOGIN_ERROR');
export const CHECKING_USER = Symbol('CHECKING_USER');

const userLoggedIn = ({currentUser}) => ({
    type: USER_LOGGED_IN,
    currentUser
});

const checkingUser = () => ({
    type: CHECKING_USER
});

const userLoginError = () => ({
    type: USER_LOGIN_ERROR
});

export const loginUser = ({ username, password }) => async (dispatch) => {
    try {
        const response = await fetch(`${process.env.API_ROOT}/guest/login`, {
            method: 'POST',
            body: {
                username,
                password
            }
        });

        console.log('response', response);
        const currentUser = response;
        // localStorage.authKey = currentUser.authKey;
        dispatch(userLoggedIn({currentUser}));
    } catch (err) {
        console.log('err', err);

        // dispatch(loginError({errorMessage}));
    }
};

export const checkUser = () => async dispatch => {
    dispatch(checkingUser());
    if (localStorage.authKey && localStorage.authKey !== 'undefined') {
        try {
            const response = await fetch(`${process.env.API_ROOT}/users/token?expand=authKey,accountId,email,username`, {
                method: 'POST',
                body: {
                    accessToken: localStorage.authKey
                }
            });
            const cardStyle = JSON.parse(localStorage.getItem('cardStyle'));
            const cardStyleReports = JSON.parse(localStorage.getItem('cardStyle_reports'));
            const cardStyleArticles = JSON.parse(localStorage.getItem('cardStyle_articles'));
            const cardStyleProjects = JSON.parse(localStorage.getItem('cardStyle_projects'));
            const userPrefs = JSON.parse(localStorage.getItem('prefs'));
            const prefs = {
                cardStyle: cardStyle === 1 ? 'list' : 'grid',
                cardStyle_reports: cardStyleReports === 1 ? 'list' : 'grid',
                cardStyle_articles: cardStyleArticles === 1 ? 'list' : 'grid',
                cardStyle_projects: cardStyleProjects === 1 ? 'list' : 'grid'
            };
            dispatch(storedGridPrefs({prefs}));
            dispatch(storedUserPrefs({userPrefs}));
            dispatch(userLoggedIn({currentUser: response}));
        } catch (err) {
            console.log(err);
            dispatch(userLoginError());
            location.replace('/login');
        }
    }
};
