import fetch from '../util/fetch';
import { loadResults } from './nav';
import { fetchProject } from './projects';
export const FETCHED_SETTINGS = Symbol('FETCHED_SETTINGS');
export const STORED_USER_PREFS = Symbol('STORED_USER_PREFS');
export const STORED_GRID_PREFS = Symbol('STORED_GRID_PREFS');

const fetchedSettings = ({settings}) => ({
    type: FETCHED_SETTINGS,
    settings
});

export const storedUserPrefs = ({userPrefs}) => ({
    type: STORED_USER_PREFS,
    userPrefs
});

export const storedGridPrefs = ({prefs}) => ({
    type: STORED_GRID_PREFS,
    gridPrefs: prefs
});

export const fetchSettings = ({projectId, noLoad}) => async (dispatch) => {
    try {
        if (!noLoad) {
            dispatch(loadResults({isLoading: true}));
        }
        await dispatch(fetchProject({projectId}));
        const response = await fetch(`${process.env.API_ROOT}/projects/${projectId}`);
        dispatch(fetchedSettings({settings: response}));
        dispatch(loadResults({isLoading: false}));
    } catch (err) {
        console.log('err', err);
        dispatch(loadResults({isLoading: false}));

        // dispatch(loginError({errorMessage}));
    }
};

export const saveName = ({ name, projectId }) => async dispatch => {
    const response = await fetch(`${process.env.API_ROOT}/projects/${projectId}`, {
        method: 'PATCH',
        body: {
            name
        }
    });
    dispatch(fetchedSettings({settings: response}));
};

export const storeUserPrefs = (userPrefs) => dispatch => {
    dispatch(storedUserPrefs({userPrefs}));
};

export const storeGridPrefs = (gridPrefs) => dispatch => {
    const prefs = gridPrefs.reduce((prev, curr) => {
        const obj = Object.assign({}, prev);
        obj[curr.value] = curr.index === 1 ? 'list' : 'grid';
        return obj;
    }, {});
    dispatch(storedGridPrefs({prefs}));
};
