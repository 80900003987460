import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Card from '../../components/modules/Card';
import ListCard from '../../components/modules/ListCard';
import Loading from '../../components/modules/Loading';
import ConfirmDialog from '../../components/modules/ConfirmDialog';
import SnackBar from '../../components/modules/SnackBar';
import SearchBar from '../../components/projects/SearchBar';
import { fetchOutputs, saveOutput, deleteOutput, addOutput } from '../../actions/outputs';
import UpdateOutput from './UpdateOutput';
import { changePage } from '../../actions/nav';
import { Grid, Button } from '@material-ui/core';

const OutputsContainer = props => {
    const [ ascending, setAscending ] = useState(true);
    const [ searchTerm, setSearchTerm ] = useState('');
    const [ cardFormat, setCardFormat ] = useState('grid');
    const [ updateId, setUpdateId ] = useState(0);
    const [ deleteId, setDeleteId ] = useState(0);
    const [ output, setOutput ] = useState({});
    const [ projectId, setProjectId ] = useState('');
    const [ addDialog, setAddDialog ] = useState(false);
    const [ snackOpen, setSnackOpen ] = useState(false);
    const [ newName, setNewName ] = useState('');

    const options = ['Update', 'Delete'];

    useEffect(() => {
        const { id } = props.match.params;
        setProjectId(id);
        props.fetchOutputs({projectId: id});
    }, [props.match.params.id]);

    useEffect(() => {
        props.changePage({
            page: props.project.name,
            image: props.project.image,
            tabs: ['reports', 'categories', 'outputs', 'settings'],
            breadcrumbs: [
                {
                    name: 'Dashboard',
                    path: '/'
                },
                {
                    name: 'Projects',
                    path: '/projects'
                }
            ]
        });
    }, [props.project.id]);

    useEffect(() => {
        setOutput(props.outputs.find(out => (out.id === updateId || out.id === deleteId)) || {});
    }, [ updateId, deleteId ]);

    const sortOutputs = () => {
        return props.outputs.filter(output => {
            if (!searchTerm) {
                return true;
            }
            return output.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
        }).sort((a, b) => {
            const first = ascending ? a : b;
            const second = ascending ? b : a;

            if (first.name.toLowerCase() < second.name.toLowerCase()) {
                return -1;
            }
            return 1;
        });
    };

    const handleSearch = ({value}) => {
        setSearchTerm(value);
    };

    const handleFormat = ({value}) => {
        setCardFormat(value);
    };

    const handleAscending = () => {
        setAscending(!ascending);
    };

    const handleSelect = ({idx, id}) => {
        if (idx === 0) {
            setUpdateId(id);
            setDeleteId(0);
        } else if (idx === 1) {
            setDeleteId(id);
            setUpdateId(0);
        }
    };

    const closeUpdate = () => {
        setUpdateId(0);
        setAddDialog(false);
    };

    const saveOutput = (data) => {
        props.saveOutput(data, updateId, projectId);
        setUpdateId(0);
    };

    const createOutput = (data) => {
        data.projectId = projectId;
        props.addOutput(data);
        setNewName(data.name);
        setAddDialog(false);
        openSnack();
    };

    const openSnack = () => {
        setSnackOpen(true);
        setTimeout(() => { setSnackOpen(false); }, 5000);
    };

    const closeDialog = () => {
        setDeleteId(0);
    };

    const handleConfirm = () => {
        props.deleteOutput({outputId: deleteId, projectId});
        closeDialog();
    };

    const handleAdd = () => {
        setUpdateId(0);
        setAddDialog(true);
    };

    const closeSnack = () => {
        setSnackOpen(false);
    };

    const outputs = sortOutputs();
    if (props.loading) {
        return <Loading />;
    }
    return (
        <Grid container spacing={0}>
            <SnackBar
                text={`Output ${newName} has been created.`}
                open={snackOpen}
                variant='success'
                handleClose={closeSnack}
            />
            <ConfirmDialog
                text={`This will delete "${output.name}."`}
                handleClose={closeDialog}
                handleConfirm={handleConfirm}
                open={!!deleteId}
            />
            <SearchBar
                ascending={ascending}
                toggleAscending={handleAscending}
                cardFormat={cardFormat}
                searchTerm={searchTerm}
                handleSearch={handleSearch}
                handleFormat={handleFormat}
            />
            <Button variant='contained' color='primary' className='center-button' onClick={handleAdd} >Add New Output</Button>
            {cardFormat === 'list'
                ? <Grid container spacing={1}>
                    {outputs.map(output => <ListCard
                        key={output.id}
                        item={output}
                        options={options}
                        link={`/outputs/${output.id}`}
                    />)}
                </Grid>
                : <Grid container spacing={4}>
                    {outputs.map(output => (<Card
                        key={output.id}
                        item={output}
                        flagText={output.id < 0 ? 'Classic' : ''}
                        options={output.id > 0 ? options : []}
                        handleSelect={handleSelect}
                        link={`/outputs/${output.id}`}
                    />))}
                </Grid>}
            <UpdateOutput
                open={!!updateId || addDialog}
                isAdding={addDialog}
                output={output}
                handleClose={closeUpdate}
                saveOutput={saveOutput}
                createOutput={createOutput}
            />
        </Grid>
    );
};

OutputsContainer.propTypes = {
    fetchOutputs: PropTypes.func,
    outputs: PropTypes.array,
    changePage: PropTypes.func,
    saveOutput: PropTypes.func,
    addOutput: PropTypes.func,
    deleteOutput: PropTypes.func,
    project: PropTypes.object,
    loading: PropTypes.bool,
    match: PropTypes.object
};

function mapStateToProps (state) {
    const { outputs, ascending } = state.outputs.toJS();
    const { project } = state.projects.toJS();
    const { loading } = state.nav.toJS();
    return { outputs, ascending, loading, project };
}
export default connect(mapStateToProps, { fetchOutputs, changePage, saveOutput, deleteOutput, addOutput })(OutputsContainer);
