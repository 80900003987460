import Immutable from 'immutable';
import * as ActionType from '../actions/settings';

const defaultState = Immutable.fromJS({
    settings: {},
    userPrefs: {},
    gridPrefs: {}
});

function settingsReducer (state = defaultState, action) {
    const { settings, userPrefs, gridPrefs } = action;

    switch (action.type) {
    case ActionType.FETCHED_SETTINGS:
        return state.merge(Immutable.fromJS({ settings }));
    case ActionType.STORED_USER_PREFS:
        return state.merge(Immutable.fromJS({ userPrefs }));
    case ActionType.STORED_GRID_PREFS:
        return state.merge(Immutable.fromJS({ gridPrefs }));
    default:
        return state;
    }
}
export default settingsReducer;
