import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { changePage } from '../../actions/nav';
import Publications from './Publications';
import Accounts from './Accounts';
import Users from './Users';
import Projects from './Projects';

const SuperUser = props => {
    const [ page, setPage ] = useState('');
    useEffect(() => {
        props.changePage({
            page: 'Super User',
            image: '',
            tabs: ['publications', 'accounts', 'users', 'projects'],
            breadcrumbs: [
                {
                    name: 'Dashboard',
                    path: '/'
                }
            ]
        });
    }, []);

    useEffect(() => {
        setPage(props.location.pathname.split('/')[2]);
    }, [props.location.pathname]);

    if (page === 'publications') {
        return (
            <Publications />
        );
    } else if (page === 'accounts') {
        return <Accounts />;
    } else if (page === 'users') {
        return <Users />;
    } else if (page === 'projects') {
        return <Projects />;
    }

    return <div />;
};

SuperUser.propTypes = {
    changePage: PropTypes.func,
    location: PropTypes.object
};

export default connect(null, { changePage })(SuperUser);
