import fetch from '../util/fetch';
import { loadResults } from './nav';
import { fetchProject } from './projects';
import { fetchReports } from './reports';
export const FETCHED_OUTPUTS = Symbol('FETCHED_OUTPUTS');
export const FETCHED_OUTPUT = Symbol('FETCHED_OUTPUT');
export const FETCHED_KEY = Symbol('FETCHED_KEY');

const fetchedOutputs = ({outputs}) => ({
    type: FETCHED_OUTPUTS,
    outputs
});

const fetchedOutput = ({output}) => ({
    type: FETCHED_OUTPUT,
    output
});

const fetchedKey = ({key}) => ({
    type: FETCHED_KEY,
    key
});

export const fetchOutputs = ({projectId, noLoad}) => async (dispatch) => {
    try {
        if (!noLoad) {
            dispatch(loadResults({isLoading: true}));
        }
        await dispatch(fetchProject({projectId}));
        const response = await fetch(`${process.env.API_ROOT}/outputs?projectId=${projectId}`);
        dispatch(fetchedOutputs({outputs: response}));
        dispatch(loadResults({isLoading: false}));
    } catch (err) {
        console.log('err', err);
        dispatch(loadResults({isLoading: false}));

        // dispatch(loginError({errorMessage}));
    }
};

export const fetchOutputKey = ({ outputId, reportId }) => async dispatch => {
    const response = await fetch(`${process.env.API_ROOT}/outputs/${outputId}/key?reportId=${reportId}`);
    const { key } = response;
    dispatch(fetchedKey({key}));
};

export const fetchOutput = ({outputId}) => async dispatch => {
    try {
        const response = await fetch(`${process.env.API_ROOT}/outputs/${outputId}`);
        await dispatch(fetchedOutput({output: response}));
        const {projectId} = response;
        await dispatch(fetchOutputs({projectId}));
        await dispatch(fetchReports({projectId}));
    } catch (err) {
        console.log('err', err);
    }
};

export const addOutput = (data) => async dispatch => {
    await fetch(`${process.env.API_ROOT}/outputs`, {
        method: 'POST',
        body: data
    });
    dispatch(fetchOutputs({projectId: data.projectId, noLoad: true}));
};

export const saveOutput = (data, outputId) => async dispatch => {
    await fetch(`${process.env.API_ROOT}/outputs/${outputId}`, {
        method: 'PATCH',
        body: data
    });
};

export const deleteOutput = ({ outputId, projectId }) => async dispatch => {
    await fetch(`${process.env.API_ROOT}/outputs/${outputId}`, {
        method: 'DELETE'
    });
    dispatch(fetchOutputs({projectId, noLoad: true}));
};
