import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import Card from '../../components/modules/Card';
import ListCard from '../../components/modules/ListCard';
import Loading from '../../components/modules/Loading';
import SearchBar from '../../components/projects/SearchBar';
import SettingsModal from '../../components/projects/SettingsModal';
import ConfirmDialog from '../../components/modules/ConfirmDialog';
import { fetchReports, archiveReport, createReport } from '../../actions/reports';
import { changePage } from '../../actions/nav';
import { Grid, Button, Modal, Paper, TextField } from '@material-ui/core';

const ReportsContainer = React.memo(props => {
    const [ ascending, setAscending ] = useState(true);
    const [ sortBy, setSortBy ] = useState('Date Added');
    const [ searchTerm, setSearchTerm ] = useState('');
    const [ cardFormat, setCardFormat ] = useState('grid');
    const [ projectId, setProjectId ] = useState(0);
    const [ updateId, setUpdateId ] = useState(0);
    const [ archiveId, setArchiveId ] = useState(0);
    const [ newReport, setNewReport ] = useState(false);
    const [ name, setName ] = useState('');
    const [ date, setDate ] = useState(moment().format('YYYY-MM-DD'));
    const options = ['Update', 'Archive Report'];

    useEffect(() => {
        setCardFormat(props.gridPrefs.cardStyle_reports || 'grid');
    }, [props.gridPrefs]);

    useEffect(() => {
        const { id } = props.match.params;
        props.fetchReports({projectId: id});
        setProjectId(id);
    }, [props.match.params.id]);

    useEffect(() => {
        props.changePage({
            page: props.project.name,
            image: props.project.image,
            tabs: ['reports', 'categories', 'keywords', 'outputs', 'settings'],
            breadcrumbs: [
                {
                    name: 'Dashboard',
                    path: '/'
                },
                {
                    name: 'Projects',
                    path: '/projects'
                }
            ]
        });
    }, [props.project.id]);

    useEffect(() => {
        if (props.newReportId) {
            props.history.push(`/reports/${props.newReportId}/articles`);
        }
    }, [props.newReportId]);

    const sortReports = () => {
        return props.reports.filter(report => {
            if (!searchTerm) {
                return true;
            }
            return report.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
        }).sort((a, b) => {
            const first = ascending ? a : b;
            const second = ascending ? b : a;
            if (sortBy === 'Date Added') {
                if (first.date === null || moment(first.date) < moment(second.date)) {
                    return -1;
                }
                return 1;
            } else {
                if (first.name.toLowerCase() < second.name.toLowerCase()) {
                    return -1;
                }
                return 1;
            }
        });
    };

    const handleNewReport = () => {
        setNewReport(true);
    };

    const handleCloseNewModal = () => {
        setNewReport(false);
    };

    const handleSort = ({value}) => {
        setSortBy(value);
    };

    const handleSearch = ({value}) => {
        setSearchTerm(value);
    };

    const handleFormat = ({value}) => {
        setCardFormat(value);
    };

    const handleAscending = () => {
        setAscending(!ascending);
    };

    const handleSelect = ({idx, id}) => {
        const option = options[idx];
        if (option === 'Update') {
            setUpdateId(id);
        } else if (option === 'Archive Report') {
            setArchiveId(id);
        }
    };

    const handleClose = () => {
        setUpdateId(0);
    };

    const handleCloseDialog = () => {
        setArchiveId(0);
    };
    const handleArchive = () => {
        props.archiveReport({reportId: archiveId, projectId});
        setArchiveId(0);
    };

    const handleName = (e) => {
        const { value } = e.target;
        setName(value);
    };

    const handleDate = (e) => {
        const { value } = e.target;
        setDate(value);
    };

    const handleSubmit = () => {
        props.createReport({name, date, projectId});
    };

    const reports = sortReports();
    if (props.loading) {
        return <Loading />;
    }
    return (
        <Grid container spacing={0} justify='center'>
            {newReport
                ? <Grid item xs={10}>
                    <Paper elevation={8}>
                        <form className='new-report-form'>
                            <Grid container direction='row'>
                                <Grid item xs={10}>
                                    <Grid container direction='column'>
                                        <Grid item>
                                            <TextField
                                                id='name'
                                                label='Report Name'
                                                fullWidth
                                                value={name}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                onChange={handleName}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                id='date'
                                                label='Date *'
                                                type='date'
                                                fullWidth
                                                value={date}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                onChange={handleDate}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <Grid container direction='column' alignItems='stretch' justify='center' className='vertical-buttons'>
                                        <Grid container justify='center'>
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                className='vertical-button'
                                                onClick={handleSubmit}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                        <Grid container justify='center'>
                                            <Button onClick={handleCloseNewModal} >Close</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Grid>
                : <Button
                    className='center-button'
                    variant='contained'
                    color='primary'
                    onClick={handleNewReport}
                >New Report</Button>}
            <SearchBar
                ascending={ascending}
                toggleAscending={handleAscending}
                sortBy={sortBy}
                cardFormat={cardFormat}
                searchTerm={searchTerm}
                handleSort={handleSort}
                handleSearch={handleSearch}
                handleFormat={handleFormat}
            />
            {cardFormat === 'list'
                ? <Grid container spacing={1}>
                    {reports.map(report => <ListCard
                        key={report.id}
                        item={report}
                        options={options}
                        handleSelect={handleSelect}
                        link={`/reports/${report.id}/articles`}
                        hideReports
                    />)}
                </Grid>
                : <Grid container spacing={4}>
                    {reports.map(report => <Card
                        key={report.id}
                        item={report}
                        options={options}
                        handleSelect={handleSelect}
                        link={`/reports/${report.id}/articles`}
                        hideReports
                    />)}
                </Grid>}
            <Modal open={!!updateId} onClose={handleClose} >
                <Grid container>
                    <Grid item xs={10} >
                        <Paper className='center archive-modal'>
                            <SettingsModal item={props.reports.find(report => (report.id === updateId))} type='report' />
                        </Paper>
                    </Grid>
                </Grid>
            </Modal>
            <ConfirmDialog
                open={!!archiveId}
                text={archiveId ? `This will archive "${props.reports.find(report => (report.id === archiveId)).name}."` : ''}
                handleClose={handleCloseDialog}
                handleConfirm={handleArchive}
            />
        </Grid>
    );
});

ReportsContainer.propTypes = {
    fetchReports: PropTypes.func,
    reports: PropTypes.array,
    changePage: PropTypes.func,
    archiveReport: PropTypes.func,
    createReport: PropTypes.func,
    project: PropTypes.object,
    gridPrefs: PropTypes.object,
    loading: PropTypes.boolean,
    newReportId: PropTypes.number,
    history: PropTypes.object,
    match: PropTypes.object
};

function mapStateToProps (state) {
    const { reports, ascending, newReportId } = state.reports.toJS();
    const { project } = state.projects.toJS();
    const { gridPrefs } = state.settings.toJS();
    const { loading } = state.nav.toJS();
    return { reports, ascending, newReportId, loading, project, gridPrefs };
}
export default connect(mapStateToProps, { fetchReports, changePage, archiveReport, createReport })(ReportsContainer);
