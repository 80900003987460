import fetch from '../util/fetch';
export const FETCHED_PUBLICATIONS = Symbol('FETCHED_PUBLICATIONS');

const fetchedPublications = ({publications}) => ({
    type: FETCHED_PUBLICATIONS,
    publications
});

export const fetchPublications = () => async dispatch => {
    try {
        const publications = await fetch(`${process.env.API_ROOT}/publications/all-names`);
        dispatch(fetchedPublications({publications}));
    } catch (err) {
        console.log('err', err);
    }
};
