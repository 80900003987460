import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogTitle,
    Select,
    DialogContent,
    DialogActions,
    MenuItem,
    InputLabel,
    Grid,
    Button
} from '@material-ui/core';

const ArticleDialog = props => {
    const [ projectId, setProject ] = useState('');
    const [ reportId, setReport ] = useState('');
    const [ reportName, setReportName ] = useState('');
    const [ categoryId, setCategory ] = useState('');

    useEffect(() => {
        setProject(props.article.projectId);
        setReport(props.article.reportId);
        setCategory(props.article.categoryId);
    }, [props.article]);

    useEffect(() => {
        const report = props.reports.length ? props.reports.find(report => (report.id === props.article.reportId)) : null;
        setReportName(report ? report.name : '');
    }, [props.article, props.reports]);

    const handleProject = (e) => {
        setProject(e.target.value);
        setReport(0);
        setCategory(0);
    };

    const handleReport = (e) => {
        setReport(e.target.value);
        setReportName(props.reports.find(report => (report.id === e.target.value)).name);
    };
    const handleCategory = (e) => {
        setCategory(e.target.value);
    };

    const handleSubmit = () => {
        if (props.type === 'move') {
            props.moveArticle({ reportId, reportName, categoryId });
        } else {
            props.copyArticle({ reportId, reportName, categoryId });
        }
    };

    return (

        <Dialog open={props.open} className='article-dialog'>
            <DialogTitle className='success-bg'>{props.type === 'move' ? 'Move to:' : 'Copy to:'}</DialogTitle>
            <DialogContent>
                <InputLabel shrink>Project</InputLabel>
                <Select
                    className='edit-article-textfield'
                    id='projectId'
                    label='Project'
                    value={projectId}
                    onChange={handleProject}
                    fullWidth
                >
                    {props.projects.map(project => (
                        <MenuItem value={project.id} key={project.id} >{project.name}</MenuItem>

                    ))}
                </Select>
                <InputLabel shrink>Report</InputLabel>
                <Select
                    className='edit-article-textfield'
                    id='reportId'
                    label='Report'
                    value={reportId}
                    onChange={handleReport}
                    fullWidth
                >
                    {props.reports.map(report => (
                        <MenuItem value={report.id} key={report.id} >{report.name}</MenuItem>

                    ))}
                </Select>
                <InputLabel shrink>Category</InputLabel>
                <Select
                    className='edit-article-textfield'
                    id='categoryId'
                    label='Category'
                    value={categoryId}
                    onChange={handleCategory}
                    fullWidth
                >
                    {props.categories.map(category => (
                        <MenuItem value={category.id} key={category.id} >{category.name}</MenuItem>

                    ))}
                </Select>

            </DialogContent>
            <DialogActions>
                <Grid container justify='center' className='edit-submit-buttons' spacing={3}>
                    <Grid item>
                        <Button
                            variant='contained'
                            onClick={handleSubmit}
                            disabled={!reportId || !projectId || !categoryId}
                        >{props.type}</Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={props.handleClose} className='error-text' >Close</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};
ArticleDialog.propTypes = {
    open: PropTypes.bool,
    projects: PropTypes.array,
    reports: PropTypes.array,
    article: PropTypes.object,
    type: PropTypes.string,
    moveArticle: PropTypes.func,
    copyArticle: PropTypes.func,
    handleClose: PropTypes.func,
    categories: PropTypes.array
};

export default React.memo(ArticleDialog);
