import fetch from '../util/fetch';
import { loadResults, successSnack, errorSnack } from './nav';
import { fetchProject } from './projects';
export const FETCHED_CATEGORIES = Symbol('FETCHED_CATEGORIES');
export const CREATED_CATEGORY = Symbol('CREATED_CATEGORY');

const fetchedCategories = ({categories}) => ({
    type: FETCHED_CATEGORIES,
    categories
});

const createdCategory = ({category}) => ({
    type: CREATED_CATEGORY,
    category
});

export const fetchProjectAndCategories = ({ projectId }) => async dispatch => {
    try {
        dispatch(loadResults({isLoading: true}));
        await dispatch(fetchProject({projectId}));
        await dispatch(fetchCategories({projectId}));
        dispatch(loadResults({isLoading: false}));
    } catch (err) {
        console.log('err', err);
        dispatch(loadResults({isLoading: false}));
    }
};

export const fetchCategories = ({projectId, noLoad}) => async (dispatch) => {
    try {
        if (!noLoad) {
            dispatch(loadResults({isLoading: true}));
        }
        const response = await fetch(`${process.env.API_ROOT}/categories?projectId=${projectId}`);
        dispatch(fetchedCategories({categories: response}));
        dispatch(loadResults({isLoading: false}));
    } catch (err) {
        console.log('err', err);
        dispatch(loadResults({isLoading: false}));
    }
};

export const reorderCategories = ({ order, projectId }) => async dispatch => {
    try {
        await fetch(`${process.env.API_ROOT}/categories/reorder`, {
            method: 'POST',
            body: {
                order,
                projectId
            }
        });
        dispatch(fetchCategories({projectId}));
    } catch (err) {
        console.log('err', err);
    }
};

export const createCategory = ({ name, projectId }) => async dispatch => {
    try {
        const category = await fetch(`${process.env.API_ROOT}/categories`, {
            method: 'POST',
            body: {
                projectId,
                name
            }
        });
        await dispatch(fetchCategories({projectId}));
        dispatch(createdCategory({ category }));
        dispatch(successSnack({ text: `Category "${name}" has been created.` }));
    } catch (err) {
        dispatch(errorSnack({ text: `Error creating category "${name}".` }));
        console.log('err', err);
    }
};

export const updateCategory = ({ projectId, id, name }) => async dispatch => {
    try {
        await fetch(`${process.env.API_ROOT}/categories/${id}`, {
            method: 'PATCH',
            body: {
                id,
                name
            }
        });
        dispatch(fetchCategories({projectId}));
        dispatch(successSnack({ text: `Category "${name}" has been updated.` }));
    } catch (err) {
        dispatch(errorSnack({ text: `Error updating category "${name}".` }));
        console.log('err', err);
    }
};

export const archiveCategory = ({ projectId, id, name }) => async dispatch => {
    try {
        await fetch(`${process.env.API_ROOT}/categories/${id}`, {
            method: 'DELETE'
        });
        dispatch(fetchCategories({projectId}));
        dispatch(successSnack({ text: `Category "${name}" has been archived.` }));
    } catch (err) {
        dispatch(errorSnack({ text: `Error archiving category "${name}".` }));
        console.log('err', err);
    }
};

export const unarchiveCategory = ({ id, name, projectId }) => async dispatch => {
    try {
        await fetch(`${process.env.API_ROOT}/categories/${id}`, {
            method: 'PATCH',
            body: {
                id,
                name,
                isArchived: false
            }
        });
        dispatch(successSnack({ text: `Category "${name}" has been unarchived.` }));
        dispatch(fetchCategories({projectId}));
    } catch (err) {
        dispatch(errorSnack({ text: `Error unarchiving category "${name}".` }));
        console.log('err', err);
    }
};
