import React from 'react';
import { CircularProgress } from '@material-ui/core';

const Loading = React.memo(props => (
    <div className='spinner-window'>
        <CircularProgress className='loading-spinner' size={48} />
    </div>
));

export default Loading;
