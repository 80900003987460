import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Paper,
    FormControl,
    FormControlLabel,
    Checkbox,
    Divider,
    Card,
    CardContent,
    CardActions,
    IconButton,
    Button,
    Grid
} from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

const BodyParser = props => {
    const [ content, setContent ] = useState([]);
    const [ moreContext, setMoreContext ] = useState(false);
    const [ paragraphNum, setParagraph ] = useState(0);

    useEffect(() => {
        if (props.keyword.id) {
            const re = new RegExp(props.keyword.keyword, 'i');
            const key = new RegExp(props.keyword.keyword, 'gi');
            const words = props.body.match(key);
            const paragraphs = props.body.split(/\n/)
                .filter(paragraph => (re.test(paragraph)))
                .map(paragraph => {
                    let arr = paragraph.split(key);
                    arr = arr.reduce((prev, curr, idx, orig) => {
                        prev.push(curr);
                        if (idx !== orig.length - 1) {
                            prev.push(<span className='yellow-bg'>{words[idx]}</span>);
                        }
                        return prev;
                    }, []);
                    return arr;
                });
            setContent(paragraphs);
        }
    }, [props.keyword.id, props.body]);

    const handleCheck = (e) => {
        setMoreContext(e.target.checked);
    };

    const handleLeft = () => {
        let num = paragraphNum - 1;
        if (num < 0) {
            num = content.length - 1;
        }
        setParagraph(num);
    };

    const handleRight = () => {
        let num = paragraphNum + 1;
        if (num === content.length) {
            num = 0;
        }
        setParagraph(num);
    };

    const joinParagraph = (paragraph) => {
        return paragraph.reduce((prev, curr) => {
            if (typeof curr === 'string') {
                return prev + curr;
            }
            return prev + curr.props.children;
        }, '');
    };

    const handleClick = () => {
        console.log('cont', content);
        const selectedParagraph = joinParagraph(content[paragraphNum]);
        const prevParagraph = paragraphNum > 0 ? joinParagraph(content[paragraphNum - 1]) : '';
        const nextParagraph = paragraphNum < content.length - 1 ? joinParagraph(content[paragraphNum + 1]) : '';
        props.handleSummary({summary: prevParagraph + '\n' + '\n' + selectedParagraph + '\n' + '\n' + nextParagraph});
        props.handleClose();
    };

    return (
        <Paper className='body-parser'>
            {content.length
                ? <Card style={{width: '100%'}}>
                    <CardContent className='body-parser-card-content'>
                        <Grid container justify='space-between'>
                            <Grid item>
                                <FormControl>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={moreContext}
                                                onChange={handleCheck}
                                                color='primary'
                                            />
                                        }
                                        label='More Context'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <Button color='primary' onClick={handleClick} >Set as Summary</Button>
                            </Grid>
                        </Grid>
                        <Divider />
                        {moreContext ? <p className='body-paragraph'>{content.length && paragraphNum > 0 ? content[paragraphNum - 1] : ''}</p> : ''}
                        <p className='body-paragraph'>{content.length ? content[paragraphNum] : ''}</p>
                        {moreContext ? <p className='body-paragraph'>{content.length && paragraphNum < content.length - 1 ? content[paragraphNum + 1] : ''}</p> : ''}
                    </CardContent>
                    <Divider />
                    <CardActions className='body-parser-card-actions'>
                        <Grid container justify='space-between' alignItems='center'>
                            <Grid item>
                                <IconButton onClick={handleLeft} >
                                    <ChevronLeft />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                {content.length ? `${paragraphNum + 1}/${content.length}` : 'No Keywords Found'}
                            </Grid>
                            <Grid item>
                                <IconButton onClick={handleRight}>
                                    <ChevronRight />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
                : <p className='empty-card'>No Keywords Found</p>
            }
        </Paper>
    );
};

BodyParser.propTypes = {
    keyword: PropTypes.object,
    handleClose: PropTypes.func,
    handleSummary: PropTypes.func,
    body: PropTypes.string
};

export default BodyParser;
