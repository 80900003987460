import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Dashboard, Settings, Work, SupervisorAccount } from '@material-ui/icons';
import { List, ListItem } from '@material-ui/core';

const SideNav = React.memo(props => {
    const setActiveItem = (e) => {
        const { id } = e.target;
        props.setActiveItem(id.slice(0, id.length - 1));
    };
    return (
        <div className='side-nav drawer flex column'>
            <div className='side-nav-group'>
                <List>
                    <Link to='/'>
                        <ListItem onClick={setActiveItem} id='dashboard1' className={`button nav-group flex vertical-container ${props.activeItem === 'dashboard' || !props.activeItem ? 'secondary' : ''}`}>
                            <Dashboard className='dark side-nav-icon' id='dashboard2' />
                            <p className='nav-text body-2 dark' id='dashboard3'>Dashboard</p>
                        </ListItem>
                    </Link>
                    <Link to='/projects'>
                        <ListItem onClick={setActiveItem} id='projects1' className={`button nav-group flex vertical-container ${props.activeItem === 'projects' ? 'secondary' : ''}`}>
                            <Work className='dark side-nav-icon' id='projects2' />
                            <p className='nav-text body-2 dark' id='projects3'>Projects</p>
                        </ListItem>
                    </Link>
                    {/* } <Link to='/search'>
                        <ListItem onClick={setActiveItem} id='search1' className={`button nav-group flex vertical-container ${props.activeItem === 'search' ? 'secondary' : ''}`}>
                            <Search className='dark side-nav-icon' id='search2' />
                            <p className='nav-text body-2 dark' id='search3'>Search</p>
                        </ListItem>
                    </Link> */}
                    <Link to='/settings'>
                        <ListItem onClick={setActiveItem} id='settings1' className={`button nav-group flex vertical-container ${props.activeItem === 'settings' ? 'secondary' : ''}`}>
                            <Settings className='dark side-nav-icon' id='settings2' />
                            <p className='nav-text body-2 dark' id='settings3'>Settings</p>
                        </ListItem>
                    </Link>
                    <Link to='/super/publications'>
                        <ListItem onClick={setActiveItem} id='super1' className={`button nav-group flex vertical-container ${props.activeItem === 'super' ? 'secondary' : ''}`}>
                            <SupervisorAccount className='dark side-nav-icon' id='super2' />
                            <p className='nav-text body-2 dark' id='super3'>Super User</p>
                        </ListItem>
                    </Link>
                </List>
            </div>
        </div>

    );
});

SideNav.propTypes = {
    setActiveItem: PropTypes.func,
    activeItem: PropTypes.string
};

export default SideNav;
