import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

import {BrowserRouter, Route, Switch} from 'react-router-dom';

import PageLayout from 'components/PageLayout';
import LoginPage from 'containers/login/LoginPage';
import Dashboard from 'containers/dashboard/Dashboard';

import CategoriesContainer from 'containers/categories/CategoriesPage';
import ProjectsContainer from 'containers/projects/Projects';
import SettingsContainer from 'containers/settings/Settings';
import ProjectSettingsContainer from 'containers/projects/Settings';
import OutputsContainer from 'containers/outputs/Outputs';
import ReportsContainer from 'containers/reports/Reports';
import ArticlesContainer from 'containers/articles/Articles';
import Issues from 'containers/articles/Issues';
import Overview from 'containers/articles/Overview';
import SuperUser from 'containers/superUser/SuperUser';
import SearchPage from 'containers/search/SearchPage';
import OutputContainer from 'containers/output/Output';
import NotFound from 'containers/NotFound';
import './styles/main.scss';

if (process.env.NODE_ENV !== 'production') {
    const {whyDidYouUpdate} = require('why-did-you-update');
    whyDidYouUpdate(React, {
        exclude: [
            'Route',
            'NoSsr',
            'Ripple',
            'BreadcrumbSeparator'
        ]
    });
}

const App = ({store}) => (
    <Provider store={store}>
        <BrowserRouter>
            <Switch>
                <Route path='/login' component={LoginPage} />
                <PageLayout>
                    <Route exact path='/' component={Dashboard} />
                    <Route path='/projects/:id/reports' component={ReportsContainer} />
                    <Route path='/projects/:id/categories' component={CategoriesContainer} />
                    <Route path='/projects/:id/outputs' component={OutputsContainer} />
                    <Route path='/projects/:id/settings' component={ProjectSettingsContainer} />
                    <Route exact path='/projects' component={ProjectsContainer} />
                    <Route exact path='/settings' component={SettingsContainer} />
                    <Route path='/reports/:id/articles' component={ArticlesContainer} />
                    <Route path='/reports/:id/issues' component={Issues} />
                    <Route path='/reports/:id/overview' component={Overview} />
                    <Route path='/outputs/:id' component={OutputContainer} />
                    <Route path='/super' component={SuperUser} />
                    <Route path='/search' component={SearchPage} />
                </PageLayout>
                <Route component={NotFound} />
            </Switch>
        </BrowserRouter>
    </Provider>
);
App.propTypes = {
    store: PropTypes.object.isRequired
};

export default App;
