import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Loading from '../../components/modules/Loading';
import SettingsModal from '../../components/projects/SettingsModal';

import { changePage } from '../../actions/nav';
import { fetchProject } from '../../actions/projects';

const SettingsContainer = props => {
    useEffect(() => {
        const { id } = props.match.params;
        props.fetchProject({projectId: id});
    }, [props.match.params.id]);

    useEffect(() => {
        props.changePage({
            tabs: ['reports', 'categories', 'outputs', 'settings'],
            image: props.project.image,
            page: props.project.name,
            breadcrumbs: [
                {
                    name: 'Dashboard',
                    path: '/'
                },
                {
                    name: 'Projects',
                    path: '/projects'
                }
            ]
        });
    }, [props.project.id]);

    if (props.loading) {
        return <Loading />;
    }
    return (
        <SettingsModal {...props} item={props.project} />
    );
};

SettingsContainer.propTypes = {
    changePage: PropTypes.func,
    fetchProject: PropTypes.func,
    saveName: PropTypes.func,
    saveUrl: PropTypes.func,
    uploadImageFile: PropTypes.func,
    saveImage: PropTypes.func,
    project: PropTypes.object,
    loading: PropTypes.bool,
    match: PropTypes.object
};

function mapStateToProps (state) {
    const { project } = state.projects.toJS();
    const { loading } = state.nav.toJS();
    return { loading, project };
}
export default connect(mapStateToProps, { changePage, fetchProject })(SettingsContainer);
