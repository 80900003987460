import React from 'react';
import PropTypes from 'prop-types';
import { Apps, List } from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

const GridListButton = props => {
    return (
        <div className='grid-list-button'>
            <h3 className='grid-list-title'>{props.title}</h3>
            <ToggleButtonGroup
                value={props.value}
                exclusive
                onChange={props.handleChange}
            >
                <ToggleButton
                    variant='contained'
                    value={0}
                >
                    <Apps />
        Grid
                </ToggleButton>
                <ToggleButton
                    value={1}
                    variant='contained'
                >
                    <List />
        List
                </ToggleButton>
            </ToggleButtonGroup>
        </div>
    );
};

GridListButton.propTypes = {
    title: PropTypes.string,
    value: PropTypes.number,
    handleChange: PropTypes.func
};

export default GridListButton;
