import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    TextField,
    Chip,
    Button,
    Grid
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { fetchKeywords } from '../../actions/keywords';
import BodyParser from './BodyParser';

const KeywordSuggest = React.memo(props => {
    const [ list, setList ] = useState('');
    const [ selectedKeyword, setSelectedKeyword ] = useState({});

    useEffect(() => {
        const defaultList = props.keywordLists.find(l => (l.isDefault));
        setList(defaultList ? defaultList.id : '');
    }, [props.keywordLists.length]);

    const handleList = (e, list) => {
        props.fetchKeywords({listId: list.value});
        setList(list ? list.value : 0);
    };

    const handleClick = (e, keywordId) => {
        e.preventDefault();
        setSelectedKeyword(props.keywords.find(key => key.id === +keywordId));
    };

    return (
        <Dialog open={props.open} onClose={props.handleClose} className='keyword-dialog'>
            <DialogTitle>Keyword Suggestions</DialogTitle>
            <DialogContent>
                <Autocomplete
                    id='list'
                    className='keyword-input'
                    options={props.keywordLists.map(keywordList => (
                        {title: keywordList.name, value: keywordList.id}
                    ))}
                    style={{width: '100%'}}
                    onChange={handleList}
                    getOptionLabel={option => option.title}
                    renderInput={params => <TextField {...params} fullWidth placeholder='Keyword List' />}
                />
                <Grid container spacing={1} justify='center'>
                    {list ? props.keywords.map(keyword => (
                        <Grid item>
                            <Chip
                                key={keyword.id}
                                label={keyword.keyword}
                                onClick={e => handleClick(e, keyword.id)}
                                id={keyword.id}
                                className={keyword.id === selectedKeyword.id ? 'secondary-bg' : 'primary-bg'}
                            />
                        </Grid>
                    )) : <h2 className='title center-text'>No Keywords</h2>}
                </Grid>
                <h2 className='title' >{props.title}</h2>
                <BodyParser body={props.body} keyword={selectedKeyword} handleSummary={props.handleSummary} handleClose={props.handleClose} />
            </DialogContent>
            <DialogActions>
                <Button className='error-text center-button' onClick={props.handleClose} >Close</Button>
            </DialogActions>
        </Dialog>
    );
});

KeywordSuggest.propTypes = {
    keywordLists: PropTypes.array,
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    fetchKeywords: PropTypes.func,
    handleSummary: PropTypes.func,
    keywords: PropTypes.array,
    title: PropTypes.string,
    body: PropTypes.string
};

function mapStateToProps (state) {
    const { keywords } = state.keywords.toJS();
    return { keywords };
}

export default connect(mapStateToProps, { fetchKeywords })(KeywordSuggest);
