import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, CardContent, Button, IconButton, CardHeader } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { saveCard } from '../../actions/accounts';

const CreditCardForm = props => {
    const [isEditing, setEditing] = useState(false);
    const [ stripe, setStripe ] = useState(null);
    const [ stripeCard, setStripeCard ] = useState(null);
    let Stripe = null;
    useEffect(() => {
        // Stripe = Stripe || null;
        if (Stripe) {
            const stripeObj = Stripe('pk_rAd4z1XBXk0dnkvZOOD5wFNOuabOF');
            setStripe(stripeObj);
            const elements = stripeObj.elements();
            const style = {
                base: {
                    fontSize: '16px',
                    fontFamily: '"Roboto", sans-serif'
                },
                invalid: {
                    color: '#000',
                    iconColor: '#d7263d'
                }
            };
            const cardElement = elements.create('card', { style });
            setStripeCard(cardElement);
            cardElement.mount('#card-element');
            cardElement.addEventListener('change', handleCardChange);
            return () => {
                if (stripeCard) {
                    stripeCard.removeEventListener('change', handleCardChange);
                    stripeCard.clear();
                }
            };
        };
    }, []);

    const handleEditing = () => {
        setEditing(!isEditing);
    };

    const handleCardChange = ({ error }) => {
        const displayError = document.getElementById('card-errors');
        if (error) {
            displayError.textContent = error.message;
        } else {
            displayError.textContent = '';
        }
    };

    const handleUpdate = () => {
        stripe.createToken(stripeCard).then((result) => {
            if (result.error) {
                const errorElement = document.getElementById('card-errors');
                errorElement.textContent = result.error.message;
            } else {
                stripeTokenHandler(result.token);
                stripeCard.clear();
            }
        });
    };

    const stripeTokenHandler = (token) => {
        const accountId = props.currentUser.id;
        props.saveCard({accountId, token: token.id});

        // const stripeCard = card.card;
        // this.account.card.brand = stripeCard.brand;
        // this.account.card.last4 = stripeCard.last4;
        // this.account.card.exp_month = stripeCard.exp_month;
        // this.account.card.exp_year = stripeCard.exp_year;
        // this.$notify('success', 'Card successfully updated.');
        // this.closePayment();
        // stripeCard.clear();
    };

    const CardTop = props => {
        return (<div className='card-file-header' >
            <p>Card on File</p>
            <IconButton onClick={handleEditing}>
                <Edit />
            </IconButton>
        </div>);
    };

    return (
        <Card xs={6} className='card-file-card'>
            <CardHeader title='Card on File' component={CardTop} />
            <CardContent>
                <div id='card-element'className={isEditing ? 'visible' : 'hidden'} />
                <div id='card-errors'className={isEditing ? 'visible' : 'hidden'} />
                {isEditing
                    ? <div>
                        <Button variant='contained' onClick={handleUpdate} >Update</Button>
                        <Button color='secondary' onClick={handleEditing} >Cancel</Button>
                    </div>
                    : <div>
                        <p>No card on file</p>
                        <Button onClick={handleEditing}>Add Card</Button>
                    </div>
                }
            </CardContent>
        </Card>);
};

CreditCardForm.propTypes = {
    currentUser: PropTypes.object,
    saveCard: PropTypes.func
};

function mapStateToProps (state) {
    const { currentUser } = state.login.toJS();
    return { currentUser };
}

export default connect(mapStateToProps, { saveCard })(CreditCardForm);
