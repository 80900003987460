import fetch from '../util/fetch';
import { loadResults, changePage, successSnack, errorSnack } from './nav';
export const FETCHED_PROJECTS = Symbol('FETCHED_PROJECTS');
export const FETCHED_RECENT_PROJECTS = Symbol('FETCHED_RECENT_PROJECTS');
export const FETCHED_PROJECT = Symbol('FETCHED_PROJECT');

const fetchedProjects = ({projects}) => ({
    type: FETCHED_PROJECTS,
    projects
});

const fetchedRecentProjects = ({recentProjects}) => ({
    type: FETCHED_RECENT_PROJECTS,
    recentProjects
});

const fetchedProject = ({project}) => ({
    type: FETCHED_PROJECT,
    project
});

export const fetchProjects = () => async (dispatch) => {
    try {
        dispatch(loadResults({isLoading: true}));
        const response = await fetch(`${process.env.API_ROOT}/projects`);

        console.log('response', response);
        dispatch(fetchedProjects({projects: response}));
        dispatch(loadResults({isLoading: false}));
    } catch (err) {
        console.log('err', err);
        dispatch(loadResults({isLoading: false}));
    }
};

export const fetchRecentProjects = () => async (dispatch) => {
    try {
        const recentProjects = JSON.parse(localStorage.getItem('recentProjects'));
        if (!recentProjects) {
            localStorage.setItem('recentProjects', JSON.stringify([]));
        } else if (recentProjects && recentProjects.length > 1) {
            dispatch(loadResults({isLoading: true}));
            const projects = [];
            for (let i = 0; i < recentProjects.length; i++) {
                const project = await fetch(`${process.env.API_ROOT}/projects/${recentProjects[i]}`);
                projects.push(project);
            }
            dispatch(fetchedRecentProjects({recentProjects: projects}));
            dispatch(loadResults({isLoading: false}));
        }
    } catch (err) {
        console.log('err', err);
        dispatch(loadResults({isLoading: false}));
    }
};

export const storeRecentProject = () => async dispatch => {

};

export const fetchProject = ({ projectId, noLoad }) => async dispatch => {
    try {
        const project = await fetch(`${process.env.API_ROOT}/projects/${projectId}`);
        dispatch(fetchedProject({project}));
    } catch (err) {
        console.log('err', err);
        dispatch(loadResults({isLoading: false}));
    }
};

export const saveName = ({ name, projectId }) => async dispatch => {
    const response = await fetch(`${process.env.API_ROOT}/projects/${projectId}`, {
        method: 'PATCH',
        body: {
            name
        }
    });
    dispatch(fetchedProject({project: response}));
    dispatch(changePage({page: response.name}));
};

export const saveUrl = ({ projectId, imageUrl }) => async dispatch => {
    try {
        const response = await fetch(`${process.env.API_ROOT}/projects/${projectId}/upload-image`, {
            method: 'POST',
            body: {
                uri: imageUrl
            }
        });
        dispatch(successSnack({ text: `Image has been saved.` }));
        dispatch(fetchedProject({project: response}));
    } catch (err) {
        dispatch(errorSnack({text: `Failed to save image.`}));
        console.log('upload error', err);
    }
};

export const saveImage = ({ image, projectId }) => async dispatch => {
    const response = await fetch(`${process.env.API_ROOT}/projects/${projectId}`, {
        method: 'PATCH',
        body: {
            image
        }
    });
    dispatch(fetchedProject({project: response}));
    dispatch(changePage({page: response.name}));
};

export const uploadImageFile = ({ file, projectId }) => async dispatch => {
    const reader = new FileReader();
    reader.onload = (event) => {
        dispatch(saveUrl({projectId, imageUrl: event.target.result}));
    };
    reader.readAsDataURL(file);
};

export const archiveProject = ({ projectId, name }) => async dispatch => {
    try {
        await fetch(`${process.env.API_ROOT}/projects/${projectId}`, {
            method: 'DELETE'
        });
        dispatch(successSnack({ text: `Project ${name} has been archived.` }));
        dispatch(fetchProjects());
    } catch (err) {
        dispatch(errorSnack({text: `Failed to archive Project ${name}.`}));
    }
};
